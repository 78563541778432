import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { toolsEvents } from '@web-3d-tool/shared-logic/src/event-bus/supportedKeys';
import { DraggableObject } from '@web-3d-tool/shared-ui';
import { get } from 'lodash';
import classNames from 'classnames';
import { eventBus } from '@web-3d-tool/shared-logic/src';
import styles from './ToolsContainer.module.css';

const ToolsContainer = (props) => {
  const { dragObjectName = 'draggableObject', eventPref = '' } = props;

  const { active: draggableObjectActive, config: draggableObjectConfig } = props[dragObjectName];

  const className = classNames(styles.absoluteObject, get(draggableObjectConfig, 'className', ''));

  return (
    <Fragment>
      {draggableObjectActive && (
        <DraggableObject
          {...draggableObjectConfig}
          className={className}
          onStart={(data) => eventBus.raiseEvent(`${toolsEvents.DRAGGABLE_OBJECT.ON_START}${eventPref}`, data)}
          onDrag={(data) => eventBus.raiseEvent(`${toolsEvents.DRAGGABLE_OBJECT.ON_DRAG}${eventPref}`, data)}
          onStop={(data) => eventBus.raiseEvent(`${toolsEvents.DRAGGABLE_OBJECT.ON_STOP}${eventPref}`, data)}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = ({ tools }) => {
  const { draggableObject, draggableObjectCompare } = tools;
  return { draggableObject, draggableObjectCompare };
};

export default connect(mapStateToProps)(ToolsContainer);
