import * as AT from '../../actionTypes';

export const updateReduxShellState = (props) => {
  return {
    type: AT.UPDATE_SHELL_STATE,
    payload: props,
    meta: {
      feature: AT.SHELL,
    },
  };
};

export const appLoaded = () => {
  return {
    type: AT.APP_LOADED,
    payload: {},
    meta: {
      feature: AT.SHELL,
    },
  };
};
export const presetLoaded = () => {
  return {
    type: AT.PRESET_LOADED,
    payload: {},
    meta: {
      feature: AT.SHELL,
    },
  };
};

export const loadPreset = ({ forceReloadPreset = false, pluginsPresetId }) => {
  return {
    type: AT.LOAD_PRESET,
    payload: { forceReloadPreset, pluginsPresetId },
    meta: {
      feature: AT.SHELL,
    },
  };
};

export const addPlugins = ({ presets }) => {
  return {
    type: AT.ADD_PLUGINS,
    payload: { presets },
    meta: {
      feature: AT.SHELL,
    },
  };
};

export const setAppPreset = ({ appPreset }) => {
  return {
    type: AT.SET_APP_PRESET,
    payload: { appPreset },
    meta: {
      feature: AT.SHELL,
    },
  };
};

export const setPluginViews = ({ id, views }) => {
  return {
    type: AT.SET_PLUGIN_VIEWS,
    payload: { id, views },
    meta: {
      feature: AT.SHELL,
    },
  };
};

export const setPluginsPresetId = ({ pluginsPresetId }) => {
  return {
    type: AT.SET_PLUGINS_PRESET_ID,
    payload: { pluginsPresetId },
    meta: {
      feature: AT.SHELL,
    },
  };
};

export const setPresetId = ({ presetId }) => {
  return {
    type: AT.SET_PRESET_ID,
    payload: { presetId },
    meta: {
      feature: AT.SHELL,
    },
  };
};

export const setPluginsZones = ({ zones }) => {
  return {
    type: AT.SET_PLUGINS_ZONES,
    payload: { zones },
    meta: {
      feature: AT.SHELL,
    },
  };
};

export const loadAOHSPreset = ({ presetProps }) => {
  return {
    type: AT.LOAD_AOHS_PRESET,
    payload: { presetProps },
    meta: {
      feature: AT.SHELL,
    },
  };
};

export const setIsAOHSMode = ({ isAOHS }) => {
  return {
    type: AT.SET_IS_AOHS_MODE,
    payload: { isAOHS },
    meta: {
      feature: AT.SHELL,
    },
  };
};

export const setSLContext = ({ context }) => {
  return {
    type: AT.SET_CONTEXT,
    payload: { context },
    meta: {
      feature: AT.SHELL,
    },
  };
};
