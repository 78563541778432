export const iteroEventBusEvents = Object.freeze({
  platformReady: 'platform-ready',
  appLoaded: 'application-loaded',
  initContext: 'init-context',
  languageChanged: 'language-changed',
  tokenExpired: 'token-expired',
  sessionUpdated: 'session-updated',
  handleError: 'handle-error',
});

export const iteroEventBusEnum = Object.freeze({
  platformReady: 1,
  appLoaded: 2,
  initContext: 3,
  languageChanged: 4,
  tokenExpired: 5,
  sessionUpdated: 6,
  handleError: 7,
});
