import React, { useEffect, useState } from 'react';
import { utils } from '@web-3d-tool/shared-logic';
import styles from './Splitter.module.css';
import styles360 from './Splitter360.module.css';
import { getWidth, isCurrentModelAliasAllowed, getCurrentModelAlias } from './Splitter.logic';

const Splitter = (props) => {
  const {
    renderComp,
    propsMatrix,
    isModelCompareActive,
    imageFrameDimentions = { width: window.innerWidth / 4 + 32, drawerWidth: 32 },
    isSplittedViewWithSidePluginActive,
    translationsForSplitter,
    isOnLanding,
    compareRowIndex,
  } = props;
  const { getIs360HubEnabled } = utils;
  const is360 = getIs360HubEnabled();
  const margin = '0 auto';
  const isGalleryViewActive = propsMatrix.flat().length === 5;

  const [, setWindowSizeReset] = useState({});

  useEffect(() => {
    const windowResize = () => {
      setWindowSizeReset({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', windowResize);
  }, []);

  return (
    <div className={styles.container}>
      {propsMatrix.map((propsArr, i) => (
        <div
          key={i}
          className={is360 ? styles360.splitterRow : styles.splitterRow}
          style={{ height: `${100 / propsMatrix.length}%` }}
        >
          {propsArr.map((componentProps, j) => (
            <div
              style={{
                width: getWidth(propsArr, is360, isSplittedViewWithSidePluginActive, imageFrameDimentions),
                height: '100%',
                ...(isOnLanding ? { margin: margin } : {}),
              }}
              key={j}
            >
              {renderComp(componentProps, i, j)}
              {isCurrentModelAliasAllowed(propsArr.length, isModelCompareActive, isGalleryViewActive) && (
                <div className={styles360.splitterRowSign}>
                  {getCurrentModelAlias({
                    split: j,
                    propsArrLength: propsArr.length,
                    isModelCompareActive,
                    compareRowIndex,
                    translationsForSplitter,
                  })}
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
export default Splitter;
