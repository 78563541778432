import { handleRequest } from './apiService-helper';
import { eventBus, globalEventsKeys } from '../event-bus';
import { OutgoingMessagesKeys } from '../constants';
import { hostCommunicationManager } from '../host-communication';
import logger from '../logger';
import { logToTimber } from '../timberLogger';
import storeCommunicationService from '../store-communication-service/store-communication.service';

const serviceName = 'refresh-request-service';
const appName = 'web-3d-tool';

const refreshRequest = (() => {
  let executed = false;

  const reset = () => {
    executed = false;
  };

  const refresh = async () => {
    if (!executed) {
      executed = true;
      try {
        const { context: sessionInfo } = storeCommunicationService.getStore() || {};
        const { refreshTokenUrl, sessionId } = sessionInfo || {};
        const { origin, pathname } = refreshTokenUrl && sessionId && new URL(refreshTokenUrl);
        const url = `${origin}${pathname}?sessionId=${sessionId}&appId=${appName}`;

        const refreshResult =
          origin &&
          pathname &&
          (await handleRequest({
            url,
            module: serviceName,
            headers: {
              Accept: 'application/json, text/plain, */*',
              Refresh: true,
            },
          }));

        const { status } = refreshResult;

        switch (true) {
          case status >= 200 && status < 300:
            const refreshTokenResult = refreshResult && (await refreshResult.json());
            const { accessToken: newAccessToken } = refreshTokenResult;

            newAccessToken &&
              storeCommunicationService.updateStore({ context: { ...sessionInfo, accessToken: newAccessToken } });

            logToTimber({
              timberData: {
                action: `api call: ${url} was successfully`,
                module: serviceName,
                type: 'object',
                actor: 'System',
                value: `raising event REFRESH_TOKEN true`,
              },
            });

            eventBus.raiseEvent(globalEventsKeys.REFRESH_TOKEN, { isRefreshed: !!newAccessToken });
            reset();
            break;
          case status === 401:
            logToTimber({
              timberData: {
                action: `api call: ${url} failed`,
                module: serviceName,
                type: 'object',
                actor: 'System',
                value: `sending event to host TOKEN_EXPIRED appName: ${appName}, eventName: ${OutgoingMessagesKeys.TOKEN_EXPIRED}, sessionId: ${sessionId}`,
              },
            });

            hostCommunicationManager.sendMessageToHost(OutgoingMessagesKeys.TOKEN_EXPIRED, {
              appName: appName,
              eventName: OutgoingMessagesKeys.TOKEN_EXPIRED,
              payload: sessionId,
            });
            break;
          default:
            logToTimber({
              timberData: {
                action: `api call: ${url} failed, status: ${status}`,
                module: serviceName,
                type: 'object',
                actor: 'System',
                value: `raising event REFRESH_TOKEN false`,
              },
            });

            eventBus.raiseEvent(globalEventsKeys.REFRESH_TOKEN, { isRefreshed: false });
            break;
        }
      } catch (error) {
        logger
          .info('Network')
          .to(['analytics', 'host'])
          .data({ ...error, module: serviceName })
          .end();

        logToTimber({
          timberData: {
            action: `api call failed, error: ${error}`,
            module: serviceName,
            type: 'object',
            actor: 'System',
            value: `raising event REFRESH_TOKEN false`,
          },
        });

        eventBus.raiseEvent(globalEventsKeys.REFRESH_TOKEN, { isRefreshed: false });
      }
    }
  };

  refresh.reset = reset;

  return refresh;
})();

export default refreshRequest;
