import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import classNames from 'classnames';
import styles from './DraggableObject.module.css';

const DraggableObject = (props) => {
  const {
    width,
    height,
    image,
    bounds,
    onStart,
    onDrag,
    onStop,
    className: classNameProp,
    style,
    position,
    debug,
    offset,
    children,
  } = props;

  const inlineStyles = { ...style, width, height };

  if (!children && image) {
    inlineStyles.backgroundImage = `url(${image})`;
  }

  const [pos, setPos] = useState({ x: position.x, y: position.y });

  const handleEvent = useCallback(
    (data) => {
      const { x, y } = data;
      setPos({ x, y });
      data.x += offset.x;
      data.y += offset.y;
      return data;
    },
    [offset.x, offset.y]
  );

  useEffect(() => {
    handleEvent(position);
  }, [handleEvent, position]);

  const handleStart = (e, d) => {
    e.preventDefault();
    const data = handleEvent(d);
    onStart && onStart(data);
  };

  const handleDrag = (e, d) => {
    e.preventDefault();
    const data = handleEvent(d);
    onDrag && onDrag(data);
  };

  const handleStop = (e, d) => {
    e.preventDefault();
    const data = handleEvent(d);
    onStop && onStop(data);
  };

  const shouldRenderObject = useMemo(() => {
    return pos.x >= 0 && pos.y >= 0;
  }, [pos.x, pos.y]);

  return (
    shouldRenderObject && (
      <Draggable
        grid={[1, 1]}
        bounds={bounds}
        onDrag={handleDrag}
        onStart={handleStart}
        onStop={handleStop}
        position={pos}
      >
        <div style={{ ...inlineStyles }} className={classNames(styles.draggableObject, classNameProp)}>
          {children}
          {debug && (
            <div>
              <div className={styles.debugCoords}>{`${pos.x + offset.x}-${pos.y + offset.y}`}</div>
              <div className={styles.debugDot} style={{ left: offset.x, top: offset.y }} />
            </div>
          )}
        </div>
      </Draggable>
    )
  );
};

DraggableObject.propTypes = {
  position: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  image: PropTypes.string,
  bounds: PropTypes.object,
  onStart: PropTypes.func,
  onDrag: PropTypes.func,
  onStop: PropTypes.func,
  debug: PropTypes.bool,
  offset: PropTypes.object,
};
DraggableObject.defaultProps = {
  position: { x: 0, y: 0 },
  width: 100,
  height: 100,
  image: null,
  bounds: {},
  onStart: null,
  onDrag: null,
  onStop: null,
  debug: false,
  offset: { x: 0, y: 0 },
};

export default DraggableObject;
