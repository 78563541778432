export { getModel, getCompareModel } from './api-requests/model-fetcher';
export { default as appSettingsService } from './api-requests/app-settings-service/app-settings-service';
export { extractItrModel } from './api-requests/model-fetcher/itr-fetcher.service';
export { getZippedObject, extractFile } from './unzip-util';
export { default as requestsManager } from './api-requests';
export { cacheManager, cacheKeys } from './cache-manager';

export {
  calcZoomToFitAll,
  createChromaticMaterial,
  createColorMaterial,
  createPhongTextureMaterial,
  createMeshsArray,
  createOcclusogramTexture,
  getAvailableSuffixForPrep,
  getBoundingBox,
  getBoundingBoxCenter,
  getCameraPosByCaseType,
  getJawByObjectKey,
  getObjectsKeysBySuffix,
  getPrepsFromModels,
  isAnyPrepsExists,
  isAnyPretreatmentJawExists,
  isOCCExistsInGeometry,
  isColorExistsInGeometry,
  isUpperJawEnable,
  isLowerJawEnable,
  isUpperDefaultJawExists,
  isLowerDefaultJawExists,
  isTextureMappingExistInModel,
  isTextureMappingExistInGeometry,
  isTextureMappingExistInTextures,
  isOCCExistsInModel,
  MaterialName,
  shouldShowModelOCCTexture,
  unCheckAllPreps,
  isLowerPretreatmentJawExists,
  isUpperPretreatmentJawExists,
  isLowerDentureCopyScanJawExists,
  isUpperDentureCopyScanJawExists,
  isUpperEmergenceProfileJawExists,
  isLowerEmergenceProfileJawExists,
  syncModelCompareCameras,
  getModelMovement,
  getCurrentJawNameByCamera,
  updateCameraPositionForCompare,
  checkRender,
  setModelPosition,
  getCamerasForCompareSync,
  extendMeshAndGeometry,
  getExistingJawsFromModel,
  mergeGeometriesByJawRelation,
} from './model-logic';

export {
  PREP_PREFIX,
  TeethNumbersConstants,
  ObjectSuffix,
  Environments,
  IncomingMessagesKeys,
  OutgoingMessagesKeys,
  AOHSPostMessagesKeys,
  EnvironmentParams,
  ExternalApps,
  ScanSystemTypes,
  BIEventType,
  SoftwareOptionsForCompany,
  ViewerSoftwareOptions,
  modelTypes,
  AOHS_Status,
} from './constants';
export { utils } from './utils';

export { featureAvaliability } from './feature-avaliability/feature-avaliability-service';

export { pluginsAvailability } from './plugins-availability/plugins-availability-service';

export { default as logger } from './logger';

export { downloadFile } from './api-requests/download-file';

export { refresh_grid } from './niri-manager';

export {
  getCaptureFilePrefix,
  createBase64ImageFromImg,
  createBase64ImageFromNode,
  createImageWithWaterMark,
  getNodeCanvas,
} from './screenshot-manager';

export {
  preparePhotosData,
  getVectorIntersects,
  getClosestPhotoObject,
  getMouseVector,
  getNormalizedLoupeCoords,
  getPhotosFileByEnv,
  isNiriEnabled,
  debug_init,
  currentActiveDebugFunctions,
  niriHasCariesParam,
  getImageInfoSource,
} from './niri-manager';

export { hostCommunicationManager } from './host-communication';

export {
  logToTimber,
  logToTimberBI,
  biMethods,
  timberWrapperService,
  missingTimberUrlParamError,
} from './timberLogger';

export { PluginLoadingState } from './plugins/pluginLoadingStates';

export { PluginState } from './plugins/pluginState';

export { addPlugins, removePlugins } from './plugins/plugins.common.logic';

export {
  eventBus,
  globalEventsKeys,
  hostEvents,
  toolsEvents,
  defaultPluginEvents,
  hostEventsErrors,
} from './event-bus';

export { settingsManager } from './settings-manager';

export { appSettingsManager } from './app-settings-manager';

export { scannerVersionManager } from './scanner-version-manager/scanner-version-manager';

export { default as interceptor } from './interceptor/interceptor';

export { default as sessionInfoService } from './sessionInfo-service/sessionInfo-service';

export { map } from './api-service';

export { wheelAnimation } from './360-animation';

export { wasmService } from './wasm-service/wasm-service';

export { default as clientCommunication } from './client-communication/client-communication-service';

export { AOHSService } from './AOHS-service';

export { default as storeCommunicationService } from './store-communication-service/store-communication.service';
