import { debounce } from 'lodash';
import logger from '../logger';

/**
 * This service is used to connect the shared logic to the Redux store and provide the ability to read and update the Redux store.
 */
const storeCommunicationService = () => {
  const reduxStateManager = {
    store: {},
    dispatch: null,
    updateReduxShellState: null,
    subscriptions: [],
  };

  /**
   * Dispatching Redux store on change.
   * Private function to create and dispatch a custom event.
   * @param {Object} store - The updated store state.
   */
  const dispatchReduxStoreUpdate = debounce((store) => {
    const { dispatch, updateReduxShellState } = reduxStateManager;

    if (!dispatch || !updateReduxShellState) {
      const message = 'Dispatch function or updateReduxShellState function is not defined.';
      logger
        .error(message)
        .to(['analytics', 'host'])
        .data({ module: 'storeCommunicationService', err: message })
        .end();
      return;
    }

    const event = new CustomEvent('onReduxStoreChange', {
      bubbles: true,
      detail: store,
    });

    dispatch(updateReduxShellState(reduxStateManager.store));
    window.dispatchEvent(event);
  }, 50);

  /**
   * Validates and transforms the input object for Redux state updates.
   *
   * This function performs the following checks and transformations:
   * 1. If the input is null or undefined, it returns false.
   * 2. If the input's prototype is not Object.prototype, it performs additional checks:
   *    a. If the input is a string, it transforms the string into an object with the string
   *       as both the key (spaces replaced with underscores) and the value.
   *    b. If the input is of any other type, it returns false.
   * 3. If the input passes the above checks, it returns the input object.
   *
   * @param {Object|string|null|undefined} propsObject - The input to be validated and transformed.
   * @returns {Object|boolean} - Returns the validated object, or false if the input is invalid.
   */
  const validateObject = (propsObject) => {
    if (propsObject === null || propsObject === undefined) return false;

    if (Object.getPrototypeOf(propsObject) !== Object.prototype) {
      if (typeof propsObject === 'string') {
        const key = propsObject.split(' ').join('_');
        return { [key]: propsObject };
      } else {
        return false;
      }
    }

    return propsObject;
  };

  return {
    /**
     * Initializes the service and the local service cache object.
     * @param {Object} props - Initial store properties.
     * @param {Function} dispatch - Redux dispatch method.
     * @param {Function} updateReduxShellState - Redux reducer action method.
     */
    initService: (props, dispatch, updateReduxShellState) => {
      if (!dispatch) {
        const message = 'Dispatch function is not defined.';
        logger
          .error(message)
          .to(['analytics', 'host'])
          .data({ module: 'storeCommunicationService', err: message })
          .end();
        return;
      }

      const validatedProps = validateObject(props) || {};

      reduxStateManager.updateReduxShellState = updateReduxShellState;
      reduxStateManager.dispatch = dispatch;
      reduxStateManager.store = { ...reduxStateManager.store, ...validatedProps };
      dispatchReduxStoreUpdate(reduxStateManager.store);
    },

    /**
     * Updates the local cache and the global Redux store.
     * @param {Object} newProps - Store properties to be updated.
     */
    updateStore: (newProps) => {
      const validatedProps = validateObject(newProps);

      if (validatedProps) {
        Object.entries(validatedProps).forEach(([key, value]) => {
          reduxStateManager.store = { ...reduxStateManager.store, [key]: value };
        });
        dispatchReduxStoreUpdate(reduxStateManager.store);
      } else {
        const message = `Cannot update store, provided properties not supported: ${newProps} of type: ${typeof newProps}`;
        logger
          .error(message)
          .to(['analytics', 'host'])
          .data({ module: 'storeCommunicationService', err: message })
          .end();
      }
    },

    /**
     * Returns the local cache store object.
     * @returns {Object} The local cache store object.
     */
    getStore: () => {
      return reduxStateManager.store;
    },

    /**
     * Clears the local cache store object.
     */
    clearStore: () => {
      reduxStateManager.store = {};
      reduxStateManager.dispatch = null;
      reduxStateManager.updateReduxShellState = null;
      reduxStateManager.subscriptions.forEach((subscription) => {
        window.removeEventListener('onReduxStoreChange', subscription);
      });
      reduxStateManager.subscriptions = [];
    },

    /**
     * Subscription method for Redux state change.
     * Allows other parts of the application to subscribe to state changes.
     * @param {Function} eventHandler - Subscriber's event handler method.
     */
    subscribe: (eventHandler) => {
      reduxStateManager.subscriptions.push(eventHandler);
      window.addEventListener('onReduxStoreChange', eventHandler);
    },

    /**
     * Unsubscribe method for Redux state change.
     * Allows other parts of the application to unsubscribe from state changes.
     * @param {Function} eventHandler - Subscriber's event handler method.
     */
    unsubscribe: (eventHandler) => {
      const index = reduxStateManager.subscriptions.indexOf(eventHandler);
      if (index > -1) {
        reduxStateManager.subscriptions.splice(index, 1);
        window.removeEventListener('onReduxStoreChange', eventHandler);
      }
    },
  };
};

export default storeCommunicationService();
