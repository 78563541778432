import * as configuration from '../constants/configurationValues.constants';
import * as scannerAssets_22B from './scanner-assets-22B/scanner-assets';
import * as scannerAssets_23A from './scanner-assets-23A/scanner-assets';
import { utils } from '../utils';
import scannerVersions from './scanner-versions.json';

const scannerAssetsVersions = (() => {
  const keysObject = {};
  Object.keys(scannerVersions).forEach((key) => {
    keysObject[key] = key;
  });
  return keysObject;
})();

const compareVersion = ({ currentVersion, versions1, versions2 }) => {
  if (!currentVersion) return false;

  const isPatchVersionExists = currentVersion.split('.').length === 4;

  const version = isPatchVersionExists ? currentVersion.slice(0, currentVersion.lastIndexOf('.')) : currentVersion;

  const version1 = versions1.find((v1) => v1[0] === version[0]) || '';
  const version2 = (versions2 && versions2.find((v2) => v2[0] === version[0])) || '';

  const compare = (v1, v2) => {
    return v1.localeCompare(v2, undefined, { numeric: true, sensitivity: 'base' });
  };

  if (version2) {
    const currentVersusV1 = compare(version, version1);
    const currentVersusV2 = compare(version, version2);
    return currentVersusV1 === 1 && currentVersusV2 >= 0;
  } else {
    return compare(version, version1) > -1;
  }
};

const getScannerVersion = () => {
  const currentScannerVersion = utils.getUrlParam({ param: configuration.ScannerPackageVersion });
  const { v22B, v23A, v23C } = scannerVersions;

  // Decending order of the versions in the switch case is important!
  switch (true) {
    case compareVersion({ currentVersion: currentScannerVersion, versions1: v23C }):
      return scannerAssetsVersions.v23C;
    case compareVersion({ currentVersion: currentScannerVersion, versions1: v22B, versions2: v23A }):
      return scannerAssetsVersions.v23A;
    case compareVersion({ currentVersion: currentScannerVersion, versions1: v22B }):
      return scannerAssetsVersions.v22B;
    default:
      return scannerAssetsVersions.v22B;
  }
};

const getScannerAssets = () => {
  const scannerAssetsVersion = getScannerVersion();
  switch (scannerAssetsVersion) {
    case scannerAssetsVersions.v23C:
    case scannerAssetsVersions.v23A:
      return scannerAssets_23A;
    case scannerAssetsVersions.v22B:
      return scannerAssets_22B;
    default:
      return scannerAssets_22B;
  }
};

/**
 * This function receives current scanner package version and an array of package versions
 * the functions returns true if the current package version is one of the package verions passed in the package versions array
 * i.e for checkScannerPackageVersions("3.0.0",[{major: 3}, {major: 4}]) the return value is true
 * @param {string} currentVersion current version of the Scanner software (i.e "3.0.0")
 * @param {[{major: number, minor: number, patch: number}]} versions array of versions [{major: number, minor: number, patch: number}]
 * @returns boolean
 */

const checkScannerPackageVersions = (currentVersion, versions) => {
  if (currentVersion === undefined || versions === undefined) return false;
  let result = false;
  const splittedVersion = currentVersion.split('.');
  const major = parseInt(splittedVersion[0]);
  const minor = parseInt(splittedVersion[1]);
  const patch = parseInt(splittedVersion[2]);
  versions.forEach((version) => {
    if (
      major === version.major &&
      (minor === version.minor || version.minor === undefined) &&
      (patch === version.patch || version.patch === undefined)
    ) {
      result = true;
      return;
    }
  });
  return result;
};

export const scannerVersionManager = {
  compareVersion,
  getScannerAssets,
  getScannerVersion,
  scannerAssetsVersions,
  checkScannerPackageVersions,
};
