import { eventBus, globalEventsKeys, hostEvents } from '../event-bus';
import { OutgoingMessagesKeys, Environments } from '../constants';
import { hostCommunicationManager } from '../host-communication';
import { logToTimber, logToTimberBI, biMethods } from '../timberLogger';
import { utils } from '../utils';
import { iteroEventBusEvents } from '../client-communication/iteroEventBus.constants';
import clientCommunication from '../client-communication/client-communication-service';
import interceptor from '../interceptor/interceptor';
import logger from '../logger';

const appName = 'web-3d-tool';
const serviceName = 'sessionInfo-service';

const AOHSSubscription = (resolve, reject, timeoutSubscription) => {
  const subscription = eventBus.subscribeToEvent(hostEvents.INIT_CONTEXT, (event) => {
    timeoutSubscription && clearTimeout(timeoutSubscription);
    subscription.unsubscribe();

    eventBus.raiseEvent(globalEventsKeys.RAISE_CLIENT_COMMUNICATION_EVENT, {
      eventHeader: { eventId: 3, eventName: iteroEventBusEvents.initContext, publisher: 'platform' },
      data: event,
    });
  });

  eventBus.subscribeToEvent(hostEvents.GET_SESSION_INFO, async (context) => {
    timeoutSubscription && clearTimeout(timeoutSubscription);

    if (context && typeof context === 'object') {
      await interceptor();
      resolve(context);
    } else {
      reject(new Error('Failed to get sessionInfo from AOHS'));
    }
  });

  clientCommunication.init();
  hostCommunicationManager.sendMessageToHost(OutgoingMessagesKeys.APP_LOADED);
};

const eupSubscription = (resolve, reject, timeoutSubscription) => {
  eventBus.subscribeToEvent(hostEvents.GET_SESSION_INFO, async (context) => {
    timeoutSubscription && clearTimeout(timeoutSubscription);

    if (context && typeof context === 'object' && context.hasOwnProperty('accessToken')) {
      await interceptor();
      resolve(context);
    } else {
      reject(new Error('Failed to get sessionInfo from MIDC'));
    }
  });

  hostCommunicationManager.sendMessageToHost(OutgoingMessagesKeys.GET_SESSION_INFO, {
    appName: appName,
    eventName: OutgoingMessagesKeys.GET_SESSION_INFO,
  });
};

const scannerSubscription = async (resolve, reject, timeoutSubscription) => {
  eventBus.subscribeToEvent(hostEvents.GET_SESSION_INFO, async (context) => {
    timeoutSubscription && clearTimeout(timeoutSubscription);

    if (context && typeof context === 'object') {
      await interceptor();
      resolve(context);
    } else {
      reject(new Error('Failed to get sessionInfo from Scanner'));
    }
  });
  clientCommunication.init();
};

const sessionInfoService = () => {
  return new Promise(async (resolve, reject) => {
    const env = utils.getEnv();

    const timeoutSubscription = setTimeout(() => {
      return reject('Session info timeout');
    }, 5000);

    try {
      switch (env) {
        case Environments.EUP:
          return eupSubscription(resolve, reject, timeoutSubscription);
        case Environments.SCANNER:
          return scannerSubscription(resolve, reject, timeoutSubscription);
        case Environments.AOHS:
          return AOHSSubscription(resolve, reject, timeoutSubscription);
        default:
          // no default
          break;
      }
    } catch (error) {
      logger
        .error(`Failed to get sessionInfo from ${env}`)
        .to(['analytics', 'host'])
        .data({ module: serviceName, err: error.message })
        .end();

      logToTimber({
        timberData: {
          action: `postMessage to parent failed`,
          module: serviceName,
          type: 'object',
          actor: 'System',
          value: {
            errorMessage: error.message,
          },
        },
      });

      logToTimberBI(
        biMethods.errorReportBiLog({
          object: serviceName,
          code: 'Post message to parent failed',
          description: error.message,
          severity: `Fatal - failed to get sessionInfo from  ${env}`,
        })
      );

      reject(false);
    } finally {
      // for standalone mode only (development)
      const isStandaloneMode = utils.isStandaloneMode();
      isStandaloneMode &&
        env !== Environments.AOHS &&
        eventBus.raiseEvent(hostEvents.GET_SESSION_INFO, { accessToken: 'dev token', loggedInUserId: 0 });
    }
  });
};
export default sessionInfoService;
