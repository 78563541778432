import { iteroEventBusEvents } from '../iteroEventBus.constants';
import { scannerMappingKeys } from '../../constants';
import { eventBus, hostEvents, globalEventsKeys } from '../../event-bus';
import { logToTimber, logToTimberBI, biMethods } from '../../timberLogger';
import storeCommunicationService from '../../store-communication-service/store-communication.service';
import logger from '../../logger';

class ClientCommunicationEventsHandlers {
  constructor(iteroEventBus, appId, refreshRequest) {
    this.serviceName = 'ClientCommunicationEventsHandlers';
    this.localStorageKey = 'sessionInfo';
    this.iteroEventBus = iteroEventBus;
    this.appId = appId;
    this.eventBus = eventBus;
    this.hostEvents = hostEvents;
    this.globalEventsKeys = globalEventsKeys;
    this.refreshRequest = refreshRequest;
    this.logToTimber = logToTimber;
    this.logToTimberBI = logToTimberBI;
    this.biMethods = biMethods;
    this.logger = logger;
  }

  emitPlatformEvent(eventName, publisherAppId, targetApplications, eventPayload, channelName = 'platform-channel') {
    const eventId = this.iteroEventBus.generateEventId();
    this.iteroEventBus.emit(channelName, publisherAppId, eventName, eventId, [...targetApplications], eventPayload);

    this.logToTimber({
      timberData: {
        action: 'emit',
        module: this.serviceName,
        type: 'object',
        actor: 'System',
        value: {
          eventId,
          eventName,
          targetApplications,
          eventPayload,
          channelName,
        },
      },
    });
  }

  platformReadyHandler(event) {
    this.emitPlatformEvent(iteroEventBusEvents.appLoaded, this.appId, ['*']);
  }

  appLoadedHandler(event) {}

  initContextHandler(event) {
    const { data: parentSessionInfo } = event;
    const contextMapObject = {};

    for (const key in parentSessionInfo) {
      if (key === 'platformServices') {
        parentSessionInfo[key].forEach((entry) => {
          const { name, url } = entry;
          contextMapObject[scannerMappingKeys[name]] = url;
        });
      } else {
        for (const param in parentSessionInfo[key]) {
          contextMapObject[scannerMappingKeys[param]] = parentSessionInfo[key][param];
        }
      }
    }

    this.eventBus.raiseEvent(this.hostEvents.GET_SESSION_INFO, contextMapObject);

    this.logger
      .to(['analytics', 'host'])
      .info(`context recieved from scanner`)
      .data({ module: this.serviceName })
      .end();
  }

  languageChangedHandler(event) {}

  tokenExpiredHandler(event) {
    const { context } = storeCommunicationService.getStore() || {};
    const { sessionId } = context || {};
    if (sessionId) {
      this.emitPlatformEvent(iteroEventBusEvents.tokenExpired, this.appId, ['platform'], { sessionId });
    } else {
      this.logger
        .to(['analytics', 'host'])
        .error(`cannot send token expired event with invalid sessionId`)
        .data({ module: this.serviceName, data: sessionId })
        .end();
    }
  }

  sessionUpdatedHandler(event) {
    try {
      const { data } = event;
      const { context: sessionInfo } = storeCommunicationService.getStore() || {};
      const updatedSessionInfo = { ...sessionInfo, ...data };

      storeCommunicationService.updateStore({ context: updatedSessionInfo });

      this.eventBus.raiseEvent(this.globalEventsKeys.REFRESH_TOKEN, { isRefreshed: true });

      this.logger
        .to(['analytics', 'host'])
        .info(`session update recieved from scanner`)
        .data({ module: this.serviceName, data: 'session update recieved from scanner.' })
        .end();
    } catch (error) {
      this.eventBus.raiseEvent(this.globalEventsKeys.REFRESH_TOKEN, { isRefreshed: false });

      this.logger
        .to(['analytics', 'host'])
        .error(error)
        .data({ module: this.serviceName, data: 'failed to update session from scanner.' })
        .end();
    }
  }

  handleErrorHandler(event) {
    const { data } = event;
    const { error, action } = data || {};
    this.logger
      .error(error)
      .to(['analytics', 'host'])
      .data({ module: this.serviceName, err: error.message })
      .end();

    this.logToTimber({
      timberData: {
        action: action,
        module: this.serviceName,
        type: 'object',
        actor: 'System',
        value: {
          errorMessage: error.message,
        },
      },
    });

    this.logToTimberBI(
      this.biMethods.errorReportBiLog({
        object: this.serviceName,
        code: error.message,
        description: error.message,
        severity: `Fatal - ${error.message}`,
      })
    );

    this.emitPlatformEvent(iteroEventBusEvents.handleError, this.appId, ['platform'], { error });
  }
}

export default ClientCommunicationEventsHandlers;
