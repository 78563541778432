import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import ImageFrame from '../../ImageFrame';
import styles from './ImageFrameManipulation2DRotation.module.css';
import Drawer2DRotation from '../Drawers/drawer2DRotation/drawer2DRotation';
import EmptyPanel from '../../ImageFramesContainer/emptyPanel';
import EnlargeFrameButton from '../../ImageFramesContainer/enlagreFrameButton';

const ImageFrameManipulation2DRotation = ({
  is360,
  width,
  height,
  rotation,
  shouldTransform,
  top,
  left,
  scale,
  onChange,
  isVertical,
  componentToRender,
  selectedPointOnImage,
  originalImageSize,
  sourcesArr,
  defaultContrast,
  defaultBrightness,
  isEnlargedFrame,
  luminaOriginalImageSize,
  elementOriginalImageSize,
  viewer360Align2DImages,
  isDrawerDisabled,
  setslidersValues,
  drawerWidth,
  frameSize,
  isReady,
  toggleEnlarge,
  isDebugEnabled,
  setEmptyPanelRef,
  isModelCompared,
  modelType,
  containerSize,
  isAOHS,
}) => {
  const emptyPanelFixedWidth = 340;
  const [brightness, setBrightness] = useState(defaultBrightness);
  const [contrast, setContrast] = useState(defaultContrast);
  const [imageZindex, setimageZindex] = useState({
    niri: { zIndex: 1 },
    color: { zIndex: 1 },
  });

  const handleChangeBrightness = (value, type) => {
    const typeBrightness = {};
    typeBrightness[type] = value;
    setBrightness({ ...brightness, ...typeBrightness });
  };

  const handleChangeContrast = (value, type) => {
    const typeContrast = {};
    typeContrast[type] = value;
    setContrast({ ...contrast, ...typeContrast });
  };

  const handleOnImageClick = (type) => {
    const imagesZindex = {
      niri: { zIndex: 1 },
      color: { zIndex: 1 },
    };
    imagesZindex[type] = { zIndex: 2 };
    setimageZindex(imagesZindex);
  };

  return (
    <>
      <div className={styles.container} style={{ flexDirection: isVertical ? 'row' : 'column' }}>
        {sourcesArr.map(({ src, type, url, isPlaceHolderIcon }, index) => (
          <Fragment key={index}>
            {(isEnlargedFrame === null || isEnlargedFrame === type) && (
              <>
                {!isPlaceHolderIcon ? (
                  <>
                    {isDebugEnabled && index === 0 && <div className={styles.imgName}>{url}</div>}
                    <ImageFrame
                      is360={is360}
                      width={width}
                      height={height}
                      src={src}
                      brightness={brightness[type]}
                      contrast={contrast[type]}
                      rotation={rotation}
                      shouldTransform={shouldTransform}
                      selectedPointOnImage={selectedPointOnImage}
                      originalImageSize={originalImageSize}
                      onChange={onChange}
                      scale={scale}
                      top={top}
                      left={left}
                      id={`${type}-image`}
                      dataBiType={type}
                      isEnlargedFrame={isEnlargedFrame}
                      type={type}
                      luminaOriginalImageSize={luminaOriginalImageSize}
                      elementOriginalImageSize={elementOriginalImageSize}
                      viewer360Align2DImages={viewer360Align2DImages}
                      numberOfItems={sourcesArr.length}
                      handleOnImageClick={handleOnImageClick}
                      imageZindex={imageZindex[type]}
                      EnlargeFrameButton={EnlargeFrameButton}
                      index={index}
                      toggleEnlarge={toggleEnlarge}
                      isModelCompared={isModelCompared}
                      modelType={modelType}
                    />
                    {componentToRender}
                  </>
                ) : (
                  <EmptyPanel
                    type={type}
                    src={src}
                    is360={is360}
                    isReady={isReady}
                    frameSize={{ ...frameSize, width: emptyPanelFixedWidth }}
                    drawerWidth={drawerWidth}
                    setEmptyPanelRef={setEmptyPanelRef}
                    index={index}
                  />
                )}
              </>
            )}
          </Fragment>
        ))}
      </div>
      <Drawer2DRotation
        width={width}
        sourcesArr={sourcesArr}
        isEnlargedFrame={isEnlargedFrame}
        isDrawerDisabled={isDrawerDisabled}
        handleChangeBrightness={handleChangeBrightness}
        handleChangeContrast={handleChangeContrast}
        setslidersValues={setslidersValues}
        containerSize={containerSize}
        isAOHS={isAOHS}
      />
    </>
  );
};

ImageFrameManipulation2DRotation.defaultProps = {
  viewer360Align2DImages: true,
  defaultContrast: { niri: 100, color: 100 },
  defaultBrightness: { niri: 100, color: 100 },
  isVertical: false,
  toggleEnlarge: noop,
};

ImageFrameManipulation2DRotation.propTypes = {
  /**
   * The image width
   */
  width: PropTypes.number,
  /**
   * The image height
   */
  height: PropTypes.number,
  /**
   * Image source
   */
  src: PropTypes.string.isRequired,
  /**
   * The rotation to present the images
   */
  rotation: PropTypes.number,
  /**
   * Set the position of the Sliders to below ImageFrame
   */
  isVertical: PropTypes.bool,
  /**
   * Callback, fired when pinch, zoom, pan changes
   */
  onChange: PropTypes.func,
  /**
   * Image id
   */
  id: PropTypes.string.isRequired,

  /**
   *  defaultBrightnesss
   */
  defaultBrightnesss: PropTypes.number,

  /**
   *  defaultContrast
   */
  defaultContrast: PropTypes.number,
  /**
   *  onBrightnessChanged
   */
  onBrightnessChanged: PropTypes.func,
  /**
   *  onContrastChanged
   */
  onContrastChanged: PropTypes.func,
  /**
   * having 360 style
   */
  is360: PropTypes.bool,
};

export default ImageFrameManipulation2DRotation;
