import mainModule from '@web-3d-tool/app/src/wasm/lib/main.js';
import wasmModule from '@web-3d-tool/app/src/wasm/lib/main.wasm';
import { initWasmModule } from '@web-3d-tool/app/src/wasm/util';
import { cacheManager, cacheKeys } from '../cache-manager';
import { eventBus, globalEventsKeys } from '../event-bus';

export const wasmService = {
  async init() {
    let emscriptenModule = cacheManager.get(cacheKeys.WASM_MODULE);
    if (!emscriptenModule) {
      emscriptenModule = await initWasmModule(mainModule, wasmModule);
      cacheManager.set(cacheKeys.WASM_MODULE, emscriptenModule);
    }
    eventBus.raiseEvent(globalEventsKeys.WASM_MODULE_INITIALIZED, emscriptenModule);
  },

  getWasmExports() {
    return new Promise((resolve, reject) => {
      const wasmAwaitTimeout = setTimeout(() => reject({}), 10000);
      const wasmModule = cacheManager.get(cacheKeys.WASM_MODULE);

      const getExportFunctions = (module) =>
        Object.fromEntries(Object.entries(module).filter(([key, value]) => key.includes('_') && value));

      if (wasmModule) {
        const exports = getExportFunctions(wasmModule);
        resolve(exports || {});
      } else {
        eventBus.subscribeToEvent(globalEventsKeys.WASM_MODULE_INITIALIZED, (wasmModule) => {
          const exports = getExportFunctions(wasmModule);
          wasmAwaitTimeout && clearTimeout(wasmAwaitTimeout);
          resolve(exports || {});
        });
      }
    });
  },
};
