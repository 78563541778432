import { get, keys } from 'lodash';
import * as THREE from 'three';
import { MeshBVH, acceleratedRaycast } from 'three-mesh-bvh';
import { mergeBufferGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { PREP_PREFIX, TeethNumbersConstants } from './../constants/model.constants';
import logger from '../logger';

let bbox_transformed = null;

export const calcZoomToFitAll = (camera, boundingBox) => {
  // we keep the bbox_transformed matrix as a global variable because the camera.matrixWorldInverse
  // is being changed every time the zoom is being changed and the second time we call this function
  // the zoom has a different value, and we want to return the same value every time.
  bbox_transformed = bbox_transformed || boundingBox.applyMatrix4(camera.matrixWorldInverse);
  const bb_width = bbox_transformed.max.x - bbox_transformed.min.x;
  const bb_height = bbox_transformed.max.y - bbox_transformed.min.y;
  const zoom_x = (camera.right - camera.left) / bb_width;
  const zoom_y = (camera.top - camera.bottom) / bb_height;
  return Math.min(zoom_x, zoom_y);
};

export const getBoundingBox = (meshes) => {
  const boundingBox = new THREE.Box3();
  meshes.forEach(({ geometry }) => {
    const bbox = geometry.boundingBox;
    bbox && boundingBox.union(bbox);
  });
  return boundingBox;
};

export const getBoundingBoxCenter = (boundingBox) => {
  const center = new THREE.Vector3();
  boundingBox && boundingBox.getCenter(center);
  return center;
};

export const createMeshsArray = (model, metadata) => {
  if (!model) return null;
  const { objects: geometries } = model;
  return keys({ ...metadata.lower_jaw, ...metadata.upper_jaw }).reduce((acc, key) => {
    const jaw = getJawByObjectKey(key);
    const { visible, material } = get(metadata, `${jaw}.${key}`);
    const geometry = geometries[key];
    const textures = getTexturesForGeometry(key, metadata.textures);
    geometry && geometry.uuid && visible && material && acc.push({ geometry, material, modelName: key, textures });
    return acc;
  }, []);
};

export const getTexturesForGeometry = (geometryName, textures) => {
  if (!geometryName || !textures || textures.length === 0) return [];
  return textures.reduce((acc, texture) => {
    if (texture.name.includes(`${geometryName}_texture`) || texture.name.includes('occ')) {
      acc.push({ map: texture.texture, name: texture.texture.name, material: texture.material });
    }
    return acc;
  }, []);
};

export const getJawByObjectKey = (objectName) => {
  if (objectName.includes('lower')) {
    return 'lower_jaw';
  }
  if (objectName.includes('upper')) {
    return 'upper_jaw';
  }
  return parseInt(objectName.replace(PREP_PREFIX, '')) > TeethNumbersConstants.UPPER_JAW_LAST_TEETH_NUMBER
    ? 'lower_jaw'
    : 'upper_jaw';
};

export const getExistingJawsFromModel = (objects) => {
  const jaws = Object.keys(objects).filter((key) => {
    const filteredKey = /^(?:upper_jaw|lower_jaw|^ada[0-9]+_adjacent)$/.exec(key);
    return filteredKey && filteredKey.input;
  });

  return jaws.reduce((acc, val) => {
    if (val && val.includes('adjacent')) {
      if (/^ada([1-9]|1[0-6])_adjacent$/g.exec(val)) acc['upper_jaw'] = 'upper_jaw';
      if (/^ada(1[7-9]|2[0-9]|3[0-2])+_adjacent$/.exec(val)) acc['lower_jaw'] = 'lower_jaw';
    } else {
      acc[val] = val;
    }
    return acc;
  }, {});
};

export const extendMeshAndGeometry = (model) => {
  THREE.Mesh.prototype.raycast = acceleratedRaycast;
  const { objects, geometry } = model;
  const geometries = objects || [geometry];
  return Object.values(geometries).map((geometry) => {
    // MeshBVH: Only BufferGeometries are supported.
    if (geometry.type === 'BufferGeometry') {
      geometry.boundsTree = new MeshBVH(geometry);
    }
    return { geometry };
  });
};

export const mergeGeometriesByJawRelation = (model, group, jawName) => {
  if (!group) return model;
  const originalModelGeometry = model.geometry.clone();
  try {
    const { children } = group || [];
    const geometries = [];
    const jawRelatedMeshes = children.filter(
      (mesh) => /^ada([1-9]|[12][0-9]|3[01])(_adjacent)?$/.exec(mesh.name) && mesh.geometry.uuid !== model.geometry.uuid
    );

    geometries.push(originalModelGeometry);
    jawRelatedMeshes.forEach((mesh) => {
      const meshRelation = getJawByObjectKey(mesh.name);
      if (meshRelation === jawName) {
        geometries.push(mesh.geometry.clone());
      }
    });

    if (geometries.length > 1) {
      const mergedGeometry = mergeBufferGeometries(geometries);
      model.geometry = mergedGeometry;
      extendMeshAndGeometry(model);
    }

    return model;
  } catch (error) {
    logger
      .error('Error merge related geometry')
      .data('Error merge related geometry')
      .to(['analytics', 'host'])
      .end();

    model.geometry = originalModelGeometry;
    return model;
  }
};
