import React, { useRef, useEffect, useContext, useImperativeHandle, useCallback } from 'react';
import PropTypes from 'prop-types';
import { extend, useThree, useRender } from 'react-three-fiber';
import { noop } from 'lodash';
import { MultiTouchControlsOrthographic } from '@web-3d-tool/three-multitouch-controls';
import { logger } from '@web-3d-tool/shared-logic';
import Jaws from '../../Jaws';

import { CameraContext } from './Camera';

extend({ MultiTouchControlsOrthographic });

let lastRenderingTimestamp;
const shouldLogEventTiming = false;

function logEventTiming(camera) {
  function numString(x) {
    return ('' + x.toFixed(0)).padStart(5, ' ');
  }

  let curTimestamp = performance.now();
  if (camera.timingInfo && lastRenderingTimestamp) {
    let { eventTime, eventReceived, cameraUpdated } = camera.timingInfo;

    logger
      .debug('debugging')
      .data({
        module: 'renderer-controls',
        'Since last rendering': numString(curTimestamp - lastRenderingTimestamp),
        'Event fired - received': numString(eventReceived - eventTime),
        'Event fired - camera updated': numString(cameraUpdated - eventTime),
        'Event fired - rendering started': numString(performance.now() - eventTime),
      })
      .end();
  } else {
    logger
      .info('Rendering without timingInfo')
      .data({ module: 'renderer-controls' })
      .end();
  }
  lastRenderingTimestamp = curTimestamp;
  camera.timingInfo = undefined;
}

export const ControlsContext = React.createContext();

const Controls = React.forwardRef((props, ref) => {
  const { children, onChange, onEnd, ...other } = props;
  const camera = useContext(CameraContext);
  const controlsRef = useRef();
  const { canvas, invalidate, size } = useThree();

  useImperativeHandle(ref, () => controlsRef);

  const updateEventListeners = () => {
    const { isAnyObjectVisibleOnScreen } = Jaws.jawsVisibility;
    if (!isAnyObjectVisibleOnScreen) {
      removeEventListeners();
    } else if (!controlsRef.current.isEventListenersActive) {
      addEventListeners();
    }
  };

  const addEventListeners = useCallback(() => {
    const { current } = controlsRef;
    current.addEventListener('start', invalidate);
    current.addEventListener('change', invalidate);
    current.addEventListener('change', onChange);
    current.addEventListener('end', onEnd);
    current.init();
  }, [invalidate, onChange, onEnd]);

  const removeEventListeners = useCallback(() => {
    const { current } = controlsRef;
    current.removeEventListener('start', invalidate);
    current.removeEventListener('change', invalidate);
    current.removeEventListener('change', onChange);
    current.removeEventListener('end', onEnd);
    current.dispose();
  }, [invalidate, onChange, onEnd]);

  useEffect(() => {
    controlsRef.current.handleResize();
  }, [size.width, size.height]);

  useRender(() => {
    if (controlsRef.current) {
      updateEventListeners();
      controlsRef.current.updateCamera();
    }

    if (shouldLogEventTiming) {
      logEventTiming(camera);
    }
  });

  return (
    <>
      <multiTouchControlsOrthographic {...other} ref={controlsRef} args={[camera, canvas]} />
      {controlsRef.current && (
        <ControlsContext.Provider value={controlsRef.current}>{children}</ControlsContext.Provider>
      )}
    </>
  );
});

Controls.propTypes = {
  dynamicDampingFactor: PropTypes.number,
  /**
   * Set false to disable this control
   */
  enabled: PropTypes.bool,
  /**
   * Set to true to disable rotating
   */
  noRotate: PropTypes.bool,
  /**
   * Set to true to disable zooming
   */
  noZoom: PropTypes.bool,
  /**
   * Set to true to disable panning
   */
  noPan: PropTypes.bool,
  /**
   * Callback, fired when double tapping with two fingers
   */
  reset: PropTypes.func,
  rotateSpeed: PropTypes.number,
  staticMoving: PropTypes.bool,
  target: PropTypes.array,
  /**
   * Callback, fired when tapping with two fingers
   */
  zoomAll: PropTypes.func,
  zoomSpeed: PropTypes.number,
  /**
   * Callback, fired when cameraChange
   */
  onChange: PropTypes.func,
  onEnd: PropTypes.func,
  /**
   * Set false to disable events control
   */
  isStaticMode: PropTypes.bool,
};

Controls.defaultProps = {
  onChange: noop,
  onEnd: noop,
  zoomAll: noop,
  reset: noop,
};

export default Controls;
