import * as JSZip from 'jszip';
import logger from '../logger';
export const getZippedObject = (arraybufferData) => {
  const data = new Uint8Array(arraybufferData);
  return JSZip.loadAsync(data);
};

export const extractFile = async (zippedData, fileName, type) => {
  const logMsg = `extracting ${fileName} type ${type}`;
  try {
    logger.time(logMsg);
    return await zippedData.file(fileName).async(type);
  } catch (e) {
    throw new Error(`Missing ${fileName} or corrupted data` + e);
  } finally {
    logger.timeEnd(logMsg);
  }
};
