import { apiConfig } from './apiConfig';
import { appSettingsManager } from '../app-settings-manager';

const { web3DViewerBffMap, scannerMATApiMap } = apiConfig;
let currentMap = { ...web3DViewerBffMap };

export const map = {
  initApiMap: (isScannerHost = true) => {
    if (isScannerHost) {
      currentMap = scannerMATApiMap;
      appSettingsManager.addSetting('apiMap', scannerMATApiMap);
    } else {
      appSettingsManager.addSetting('apiMap', web3DViewerBffMap);
    }
  },

  apiMap: (selector) => {
    const apiMap = appSettingsManager.getAppSettingsByValue('apiMap');
    return selector ? apiMap[selector] : apiMap;
  },

  apiMapKeys: (key) => {
    const apiMapKeysObj = {};
    Object.keys(currentMap).forEach((key) => {
      apiMapKeysObj[key] = key;
    });
    return key ? apiMapKeysObj[key] : apiMapKeysObj;
  },
};
