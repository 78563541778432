import { values, set, omit, get, includes } from 'lodash';
import flatten from 'flat';
import * as plugins from '../constants/tools.constants';
import { default as logger } from '../logger';
import { defaultPluginEvents, globalEventsKeys, hostEvents, toolsEvents } from './supportedKeys';

const getPluginEvents = (plugins) => {
  const obj = {};

  const addEvents = (pluginId) => {
    obj[pluginId] = {};
    for (const eventKey in defaultPluginEvents) obj[pluginId][eventKey] = `${pluginId}.${eventKey}`;
  };

  for (const pluginKey in plugins) {
    const plugin = plugins[pluginKey];
    plugin && addEvents(plugin.id);
  }

  return obj;
};

const SUPPORTED_KEYS = {
  keys: values(flatten({ ...getPluginEvents(plugins), ...globalEventsKeys, ...toolsEvents, ...hostEvents })),
};

window.globalSubscriptions = {};
export const eventBus = {
  updateKeys: (newPlugins) => {
    const { keys } = SUPPORTED_KEYS;
    const newKeys = getPluginEvents(newPlugins);
    SUPPORTED_KEYS.keys = values(flatten({ ...keys, ...newKeys }));
  },
  removeKeys: (plugins) => {
    const { keys } = SUPPORTED_KEYS;
    SUPPORTED_KEYS.keys = keys.filter((key) => !plugins.includes(key.split('.')[0]));
  },
  subscribeToEvent: (key, func) => {
    const { keys } = SUPPORTED_KEYS;
    if (includes(keys, key)) {
      const id = (new Date().valueOf().toString() + Math.random()).replace('.', '');
      key && (window.globalSubscriptions = set(window.globalSubscriptions, `${key}.${id}`, func));
      return {
        unsubscribe: () => {
          key && (window.globalSubscriptions = omit(window.globalSubscriptions, `${key}.${id}`));
        },
      };
    } else {
      const message = `key is not supported! key: ${key}. Supported keys are: ${keys.join(', ')}`;
      logger
        .warn(message)
        .data({ module: 'event-bus' })
        .end();
      return null;
    }
  },
  raiseEvent: async (messageKey, payload) => {
    const subscriptions = get(window.globalSubscriptions, messageKey);
    if (subscriptions) {
      const promises = Object.values(subscriptions).map((func) => Promise.resolve(func(payload)));
      await Promise.all(promises);
    }
  },
};
