import { utils } from '../../utils';
import logger from '../../logger';
import { getITRModelWithPolling, fetchNiriFileWithPolling } from '../../api-requests/model-fetcher/itr-fetcher.service';
import * as configuration from '../../constants/configurationValues.constants';
import { settingsManager } from '../../settings-manager';
import { Environments } from '../../constants/environment.constants';
import { map } from '../../api-service/apiMap';
import { logToTimberBI, biMethods } from '../../timberLogger';

const { apiMapKeys } = map;
const modelTypes = {
  ITR: 'itr',
  NIRI: 'niri',
};

const getNiriFilePath = () => {
  const rxGuid = settingsManager.getConfigValue(configuration.rxGuid);
  const orderId = settingsManager.getConfigValue(configuration.orderId);
  return rxGuid ? { rxGuid } : { orderId };
};

const getModelUrl = () => {
  const rxGuid = settingsManager.getConfigValue(configuration.rxGuid);
  const orderId = settingsManager.getConfigValue(configuration.orderId);
  return rxGuid || orderId;
};

const getNiriFile = async (progressCB, niriPath) => {
  try {
    const niriFilePath = niriPath || getNiriFilePath();
    const isStandalone = utils.isStandaloneMode();

    const requestParams = isStandalone
      ? {
          url: `models/${niriFilePath.orderId}.niri`,
        }
      : {
          selector: apiMapKeys('getModelByType'),
          queryParams: {
            type: modelTypes.NIRI,
            ...niriFilePath,
          },
        };

    return await fetchNiriFileWithPolling({
      requestParams,
      environment: Environments.SCANNER,
      progressCB,
    });
  } catch (error) {
    logger
      .error('No NIRI file path')
      .data({ module: 'scannerRequests' })
      .end();

    logToTimberBI(
      biMethods.errorReportBiLog({
        object: 'scannerRequests',
        code: 'No NIRI file path',
        description: error.message,
        severity: 'Fatal - No NIRI file path',
      })
    );
  }
};

const getModel = async ({ queryParams }) => {
  const { rxGuid, orderId, type } = queryParams;
  const isStandalone = utils.isStandaloneMode();

  const requestParams = isStandalone
    ? {
        url: `models/${rxGuid || orderId}.${type}`,
      }
    : {
        selector: apiMapKeys('getModelByType'),
        queryParams,
      };
  return await getITRModelWithPolling(Environments.SCANNER, requestParams);
};

const getItrModel = async () => {
  const rxGuid = settingsManager.getConfigValue(configuration.rxGuid);
  const orderId = settingsManager.getConfigValue(configuration.orderId);
  return await getModel({
    queryParams: {
      type: modelTypes.ITR,
      ...(rxGuid && { rxGuid }),
      ...(orderId && { orderId }),
    },
  });
};

const getCompareItrModel = async (orderId) => {
  return await getModel({
    queryParams: {
      orderId,
      type: modelTypes.ITR,
    },
  });
};

export { getNiriFile, getItrModel, getCompareItrModel, getModelUrl, getNiriFilePath };
