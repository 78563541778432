import { apiHeaders } from '../constants/apiEnums';
import { utils } from '../utils';
import { eventBus, globalEventsKeys } from '../event-bus';
import { logToGAAndTimber, addHeaders } from '../api-service/apiService-helper';
import { getTimberUrls } from '../timberLogger/timber';
import refreshRequest from '../api-service/refreshService';
import { iteroEventBusEvents } from '../client-communication/iteroEventBus.constants';

const { X_Company_ID, X_Doctor_ID, X_LOGONAS_ID, Authorization } = apiHeaders;
const { getDoctorId, getCompanyId, getLogonasId, isScannerHostEnv, isEupHostEnv, getAccessToken } = utils;

const setCustomHeaders = () => {
  const eupCustomHeaders = {
    [X_Doctor_ID]: getDoctorId(),
    [X_Company_ID]: getCompanyId(),
    [X_LOGONAS_ID]: getLogonasId(),
    [Authorization]: getAccessToken(),
  };
  return eupCustomHeaders;
};

export const getRestrictedOriginsForSession = async () => {
  const [timberBiUrl, timberUrl] = await getTimberUrls();
  return [timberBiUrl, timberUrl];
};

export const setSessionHeaders = ({ headers = new Headers() }) => {
  const sessionHeadersObject = setCustomHeaders();
  return addHeaders({ headersObj: sessionHeadersObject, headers });
};

export default () => {
  return new Promise((resolve) => {
    const { fetch: originalFetch } = window;

    window.fetch = async (...args) => {
      const [resource, config] = args;
      const { headers, options } = config || {};
      const isRefreshRequest = headers && headers.get('refresh') === 'true';
      const restrictedOrigins = await getRestrictedOriginsForSession();
      const isRestrictedOrigin = restrictedOrigins.includes(resource);
      const shouldSetSessionHeaders = headers && !isRestrictedOrigin;
      shouldSetSessionHeaders && setSessionHeaders({ headers });

      const response = await originalFetch(resource, config);

      if (!isRestrictedOrigin && !isRefreshRequest && response.status === 401) {
        return new Promise(async (resolve, reject) => {
          let logData = {};
          try {
            eventBus.subscribeToEvent(globalEventsKeys.REFRESH_TOKEN, async ({ isRefreshed }) => {
              logData = {
                howRequestWasProcessed: isRefreshed ? 'successfully' : 'failed',
                data: `Is token refreshed: ${isRefreshed}`,
              };
              const refreshedResponse = isRefreshed ? await fetch(resource, config) : response;
              return resolve(refreshedResponse);
            });

            if (isScannerHostEnv()) {
              if (window.iteroEventBus) {
                eventBus.raiseEvent(globalEventsKeys.RAISE_CLIENT_COMMUNICATION_EVENT, {
                  eventHeader: { eventId: 5, eventName: iteroEventBusEvents.tokenExpired, publisher: 'platform' },
                });
              } else {
                logData = {
                  howRequestWasProcessed: 'Failed',
                  errorMessage: `Unauthorized response while fetching url: ${resource} - status: ${response.status}`,
                };
                return resolve(response);
              }
            }

            if (isEupHostEnv()) {
              await refreshRequest();
            }
          } catch (error) {
            logData = {
              howRequestWasProcessed: 'Failed',
              errorMessage: `Unauthorized response while fetching url: ${resource} - status: ${response.status}`,
            };
            return resolve(response);
          } finally {
            logToGAAndTimber({
              url: resource,
              options: options || {},
              isForced: false,
              module: 'interceptor',
              ...logData,
            });
          }
        });
      } else {
        return response;
      }
    };
    resolve(true);
  });
};
