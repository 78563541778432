import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import ImageManipulationSlider from '../../../ImageManipulationSlider';
import styles from './contrast-brightness-sliders.module.css';
import brightnessIcon from '../assets/brightness.png';
import contrastIcon from '../assets/contrast.png';
import { logValueChangesUsingTimber } from '../../timberWrapper';

const ContrastBrihgtnessSliders = (props) => {
  const CONNTRAST_MIN_VALUE = 0;
  const CONNTRAST_MAX_VALUE = 200;
  const {
    type,
    width,
    defaultBrightness,
    defaultContrast,
    isVertical,
    dataBiType,
    onContrastChanged,
    onBrightnessChanged,
    handleChangeBrightness,
    handleChangeContrast,
    slidersInlineStyle,
  } = props;
  const [brightness, setBrightness] = useState(defaultBrightness);
  const [contrast, setContrast] = useState(defaultContrast);
  const [, setBrighnessPrevValue] = useState(100);
  const [, setContrastPrevValue] = useState(100);

  const handleContrastChange = (value) => {
    onContrastChanged(value);
    handleChangeContrast(value, type);
    setContrast(value);
  };

  const handleBrightnessChange = (value) => {
    onBrightnessChanged(value);
    handleChangeBrightness(value, type);
    setBrightness(value);
  };

  const handleBrightnessBeforeChange = (value) => {
    setBrighnessPrevValue(value);
  };

  const handleBrightnessAfterChange = (value) => {
    logValueChangesUsingTimber({
      changeType: 'brightness',
      value,
      dataBiType,
    });
  };

  const handleBeforeContrastChange = (value) => {
    setContrastPrevValue(value);
  };

  const handleAfterContrastChange = (value) => {
    logValueChangesUsingTimber({
      changeType: 'contrast',
      value,
      dataBiType,
    });
  };

  return (
    <>
      <div
        className={styles.sliders}
        style={{
          flexDirection: isVertical ? 'column' : 'row',
          width: isVertical ? 'auto' : width,
          height: isVertical ? 'auto' : '33px',
          ...slidersInlineStyle,
        }}
      >
        <ImageManipulationSlider
          value={brightness}
          onChange={handleBrightnessChange}
          onBeforeChange={handleBrightnessBeforeChange}
          onAfterChange={handleBrightnessAfterChange}
          defaultValue={defaultBrightness}
          icon={brightnessIcon}
          isVertical={isVertical}
          dataTestId={`${dataBiType}-brightness-slider`}
        />
        <ImageManipulationSlider
          value={contrast}
          onChange={handleContrastChange}
          onBeforeChange={handleBeforeContrastChange}
          onAfterChange={handleAfterContrastChange}
          defaultValue={defaultContrast}
          min={CONNTRAST_MIN_VALUE}
          max={CONNTRAST_MAX_VALUE}
          icon={contrastIcon}
          isVertical={isVertical}
          dataTestId={`${dataBiType}-contrast-slider`}
        />
      </div>
    </>
  );
};

ContrastBrihgtnessSliders.defaultProps = {
  defaultContrast: 100,
  defaultBrightness: 100,
  isVertical: true,
  onBrightnessChanged: noop,
  onContrastChanged: noop,
  handleChangeBrightness: noop,
  handleChangeContrast: noop,
};

ContrastBrihgtnessSliders.propTypes = {
  /**
   * The image width
   */
  width: PropTypes.number,
  /**
   * The default brightness of the feature
   */
  defaultBrightness: PropTypes.number,
  /**
   * The default contrast of the feature
   */
  defaultContrast: PropTypes.number,
  /**
   * The feature position vertical / horizontal
   */
  isVertical: PropTypes.bool,
};

export default ContrastBrihgtnessSliders;
