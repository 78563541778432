import React, { Fragment, useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useThree } from 'react-three-fiber';
import {
  isTextureMappingExistInGeometry,
  isTextureMappingExistInTextures,
  MaterialName,
} from '@web-3d-tool/shared-logic';
import MarginLine from './MarginLine/MarginLine';
import { MeshWithTexture } from './Textures/MeshWithTexture';

const Scene = (props) => {
  const { meshes, onMount, isLuminaScan, bite } = props;
  const [shouldRenderMeshes, setShouldRenderMeshes] = useState(false);
  const { invalidate } = useThree();
  const shouldRenderMeshOnly = useRef(true);

  useEffect(() => {
    onMount();
    setShouldRenderMeshes(true);
    normalizeCanvasByScreenSize();

    const handleResize = () => normalizeCanvasByScreenSize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    invalidate();
  });

  const shouldRenderMeshWithoutTM = useMemo(
    () => (textures, material, geometry) => {
      const noTextures = !textures || textures.length === 0;
      return (
        noTextures ||
        material.name === MaterialName.chromatic ||
        !isTextureMappingExistInGeometry(geometry) ||
        !isTextureMappingExistInTextures(textures)
      );
    },
    []
  );

  return (
    <>
      {shouldRenderMeshes && (
        <group>
          {meshes.map(({ geometry, material, modelName, textures = [] }) => (
            <Fragment key={geometry.uuid}>
              {(shouldRenderMeshOnly.current = shouldRenderMeshWithoutTM(textures, material, geometry))}
              {material.type !== 'LineBasicMaterial' && (
                <Fragment>
                  {shouldRenderMeshOnly.current && <mesh geometry={geometry} material={material} name={modelName} />}
                  {textures.map((texture) => (
                    <MeshWithTexture
                      texture={texture}
                      geometry={geometry}
                      modelName={modelName}
                      key={`${geometry.uuid}_${texture.name}`}
                      shouldRenderTextureMapping={!shouldRenderMeshOnly.current}
                      isLuminaScan={isLuminaScan}
                    />
                  ))}
                </Fragment>
              )}

              {material.type === 'LineBasicMaterial' && <MarginLine geometry={geometry.geometry} bite={bite} />}
            </Fragment>
          ))}
        </group>
      )}
    </>
  );
};

const normalizeCanvasByScreenSize = () => {
  // This prevents blinking of the 3D model in the first 10-15 seconds of launching the viewer if the browser is not set to 100% zoom level.
  // ITEROBIZ-57950 explains it in detail.
  const currentBrowserZoomScalePercentage = Math.round((window.outerWidth / window.innerWidth) * 100);
  const canvas = document.querySelectorAll('canvas')[0];
  if (currentBrowserZoomScalePercentage < 100) {
    canvas.style.height = '0px';
  }
};

Scene.propTypes = {
  meshes: PropTypes.arrayOf(
    PropTypes.shape({
      geometry: PropTypes.object,
      material: PropTypes.object,
      modelName: PropTypes.string,
      textures: PropTypes.arrayOf(
        PropTypes.shape({
          map: PropTypes.object,
          material: PropTypes.object,
          name: PropTypes.string,
        })
      ),
    })
  ),
  /**
   * Callback, fired when Scene is rendered
   */
  onMount: PropTypes.func,
};

Scene.defaultProps = {
  meshes: [],
  onMount: () => {},
};

export default Scene;
