import { eupRequests } from './eup-requests';
import { scannerRequests } from './scanner-requests';
import { AOHSRequests } from './AOHS-requests';
import { testRequests } from './test-requests';
import { utils } from '../utils';
import { Environments, automationTestMode } from '../constants/environment.constants';

const getRequestsService = () => {
  if (utils) {
    const isAutomationTestMode = utils.getIsAutomationTestMode();
    const env = isAutomationTestMode ? automationTestMode : utils.getEnv();

    switch (env) {
      case Environments.EUP:
        return eupRequests;
      case Environments.SCANNER:
        return scannerRequests;
      case Environments.AOHS:
        return AOHSRequests;
      case automationTestMode:
        return testRequests;
      default:
        // no default
        break;
    }
  }
};

const requestsManager = {
  ...getRequestsService(),
};

export default requestsManager;
