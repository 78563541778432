import React, { useState, useEffect } from 'react';
import { logToTimber } from '@web-3d-tool/shared-logic';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import stylesNo360 from './drawerPanel.module.css';
import styles360 from './drawerPanel360.module.css';

const DrawerPanel = (props) => {
  const { is360, sourcesArr, isDrawerDisabled } = props;

  const styles = is360 ? styles360 : stylesNo360;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    logToTimber({
      timberData: {
        eventName: `${isDrawerDisabled ? 'Opening' : 'Closing'} drawer`,
        action: 'click',
        module: 'UI-actions',
        type: 'button',
      },
    });
    if (isDrawerDisabled) return;
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    sourcesArr.length === 0 && setIsDrawerOpen(false);
  }, [sourcesArr.length]);

  return (
    <>
      <div
        className={classNames(styles.drawer, {
          [styles.drawer_open]: isDrawerOpen,
        })}
        onClick={toggleDrawer}
        data-test-id="drawer"
        id="drawer"
      >
        <i className={classNames(styles.arrow, { [styles.arrow_disabled]: isDrawerDisabled })} />
      </div>
    </>
  );
};

DrawerPanel.defaultProps = {
  isDrawerOpen: false,
  isDrawerDisabled: true,
};

DrawerPanel.propTypes = {
  /*
   * The images IOC / NIRI enabled count
   */
  sourcesArr: PropTypes.array,
  /*
   * Is the image in enlarged mode
   */
  isEnlargedFrame: PropTypes.bool,
  /*
   * Is the tool is disabled
   */
  isDrawerDisabled: PropTypes.bool,
};

export default DrawerPanel;
