import {
  logger,
  appSettingsManager,
  appSettingsService,
  sessionInfoService,
  map,
  OutgoingMessagesKeys,
  hostCommunicationManager,
  AOHSService,
  utils,
  storeCommunicationService,
} from '@web-3d-tool/shared-logic';

const handlerReferences = new Map();

const resolver = () => {
  return new Promise((resolve) => {
    const appSettingsChangeHandler = ({ detail }) => {
      const { appSettings } = detail;
      if (appSettings) {
        const storedHandler = handlerReferences.get(appSettingsChangeHandler);
        storeCommunicationService.unsubscribe(storedHandler);
        resolve(true);
      }
    };

    handlerReferences.set(appSettingsChangeHandler, appSettingsChangeHandler);
    storeCommunicationService.subscribe(appSettingsChangeHandler);
  });
};

const getAllSettingsHandler = (settings) => {
  const handler = async ({ detail }) => {
    try {
      const { context } = detail;
      if (context) {
        const storedHandler = handlerReferences.get(handler);
        storeCommunicationService.unsubscribe(storedHandler);
        const appSettings = await appSettingsService.getAllSettings();
        storeCommunicationService.updateStore({ appSettings: { ...appSettings, ...settings } });
        await appSettingsManager.init(appSettings);
      }
    } catch (error) {
      logger
        .error('Failed to fetch appSettings')
        .to(['analytics', 'host'])
        .data({ module: 'app shell', err: error.message })
        .end();
    }
  };
  handlerReferences.set(handler, handler);
  return handler;
};

const initModeAOHS = async (dispatch, loadPreset, loadAOHSPreset, loadNiri, setSLContext) => {
  const allFlagsState = {
    getFeaturesToggleSettings: [],
  };
  const { initApiMap, apiMapKeys } = map;

  try {
    const isScannerEnv = utils.isScannerHostEnv();
    initApiMap(isScannerEnv, true);

    allFlagsState.getFeaturesToggleSettings = await appSettingsService.requestSingle({
      selector: apiMapKeys('getAllFlagsState'),
    });
  } catch (error) {
    logger
      .warn('Failed to load Feature Flags')
      .to(['analytics', 'host'])
      .data({ module: 'app shell', err: error.message })
      .end();
  }

  AOHSService.init(dispatch, loadPreset, loadAOHSPreset, loadNiri);

  try {
    storeCommunicationService.subscribe(getAllSettingsHandler(allFlagsState));
    const context = await sessionInfoService();
    dispatch([setSLContext({ context })]);
  } catch (err) {
    logger
      .error('Failed to init session info service')
      .to(['analytics', 'host'])
      .data({ module: 'app shell', err: err.message })
      .end();
    hostCommunicationManager.sendMessageToHost(OutgoingMessagesKeys.ERROR_MESSAGE, {
      error: 'Failed to init session info service',
    });
    return;
  }

  return await resolver();
};

const eupInit = async (dispatch, setSLContext) => {
  const allFlagsState = {
    getFeaturesToggleSettings: [],
  };
  const { initApiMap, apiMapKeys } = map;

  try {
    initApiMap(false, true);
    const featureFlagsSelector = 'getAllFlagsState';

    allFlagsState.getFeaturesToggleSettings = await appSettingsService.requestSingle({
      selector: apiMapKeys(featureFlagsSelector),
    });
  } catch (error) {
    logger
      .warn('Failed to load Feature Flags')
      .to(['analytics', 'host'])
      .data({ module: 'app shell', err: error.message })
      .end();
  }

  try {
    storeCommunicationService.subscribe(getAllSettingsHandler(allFlagsState));
    const context = await sessionInfoService();
    dispatch([setSLContext({ context })]);
  } catch (err) {
    logger
      .error('Failed to init session info service')
      .to(['analytics', 'host'])
      .data({ module: 'app shell', err: err.message })
      .end();
    hostCommunicationManager.sendMessageToHost(OutgoingMessagesKeys.ERROR_MESSAGE, {
      error: 'Failed to init session info service',
    });
    return;
  }

  return await resolver();
};

const scannerInit23C = async (dispatch, setSLContext) => {
  const allFlagsState = {
    getFeaturesToggleSettings: [],
  };
  const { initApiMap, apiMapKeys } = map;

  try {
    initApiMap(true, true);
    const featureFlagsSelector = 'getFeaturesToggleSettings';

    allFlagsState.getFeaturesToggleSettings = await appSettingsService.requestSingle({
      selector: apiMapKeys(featureFlagsSelector),
    });
  } catch (error) {
    logger
      .warn('Failed to load Feature Flags')
      .to(['analytics', 'host'])
      .data({ module: 'app shell', err: error.message })
      .end();
  }

  try {
    storeCommunicationService.subscribe(getAllSettingsHandler(allFlagsState));
    const context = await sessionInfoService();
    dispatch([setSLContext({ context })]);
  } catch (err) {
    logger
      .warn('Failed to init session info service')
      .to(['analytics', 'host'])
      .data({ module: 'app shell', err: err.message })
      .end();
  }

  return await resolver();
};

const scannerInit23A = async () => {
  const { initApiMap, apiMapKeys } = map;

  try {
    initApiMap(true, true);
    const featureFlagsSelector = 'getFeaturesToggleSettings';

    await appSettingsService.requestSingle({ selector: apiMapKeys(featureFlagsSelector) });
  } catch (error) {
    logger
      .warn('Failed to load Feature Flags')
      .to(['analytics', 'host'])
      .data({ module: 'app shell', err: error.message })
      .end();
  }

  const appSettings = await appSettingsService.getAllSettings();
  await appSettingsManager.init(appSettings);
};

export { eupInit, scannerInit23A, scannerInit23C, initModeAOHS, getAllSettingsHandler, resolver };
