import { Vector3, Matrix4, Raycaster, MathUtils } from 'three';
import { throttle } from 'lodash';
import { cacheManager } from '../../cache-manager';
import { default as logger } from '../../logger';
import { get_cam_to_abs_tx, lin_mult, getCamToUiTx } from './utils/threeUtils';
import const_params from './const_params';
import { toolsEvents } from '../../event-bus/supportedKeys';
import { utils } from '../../utils';
import Rect from './classes/rect';
import { getImageCenter } from '../../niri-manager/niri-manager.logic';

const { max_distance_to_selected_pt_mm, max_image_rotation_threshold, degreValues } = const_params;
const cache = {
  rotation: 0,
  pointInsideSurface: new Vector3(),
};

export const niriHasCariesParam = (jawsObject, jawName) =>
  jawsObject[jawName].images.some(({ niri }) => {
    const { caries_detection_score } = niri || {};
    return caries_detection_score >= 0;
  });

export const getImageInfoSource = (isEnableNGNiriImageSelectionAlgo, jawsObject, jawName, modelType) => {
  const isNiriHasCariesParam = niriHasCariesParam(jawsObject, jawName);
  const imageInfoSource = isEnableNGNiriImageSelectionAlgo && isNiriHasCariesParam ? 'niri' : 'color';

  if (isNiriHasCariesParam && isEnableNGNiriImageSelectionAlgo) {
    logger
      .info(`Caries detection algo is on for: ${modelType}`)
      .to(['analytics', 'host'])
      .data({ module: 'lumina algorithm', data: `Caries detection algo is on for: ${modelType}` })
      .end();
  }

  return imageInfoSource;
};

export const getCameraTransforms = (dataJson, imageInfoSource, camera_id) => {
  const { scan_to_cam_tx: averageScanToCamTx, camera_to_pixel: averageCameraToPixelTx, calibration_data } = dataJson;

  const cameraTransforms = {
    scan_to_cam_tx: averageScanToCamTx,
    camera_to_pixel: averageCameraToPixelTx,
    roi: {
      x_min: 0,
      x_max: 960,
      y_min: 0,
      y_max: 540,
    },
  };

  if (calibration_data) {
    const camera_calibration_data = Object.values(dataJson.calibration_data)
      .filter((cData) => cData.type === imageInfoSource)
      .find((cD) => cD.camera_id === camera_id);
    /**
     * this parameer will be used in future version of NIRI improvement.
     */
    //cameraTransforms.scan_to_cam_tx = camera_calibration_data.scan_to_cam_tx;
    cameraTransforms.camera_to_pixel = camera_calibration_data.camera_to_pixel;
    if (imageInfoSource === 'niri') {
      cameraTransforms.roi = {
        x_min: 0,
        x_max: 480,
        y_min: 0,
        y_max: 270,
      };
    }
  }

  return cameraTransforms;
};

export const initialize_image_meta_data = ({
  currentActiveJaw,
  jawName,
  mesh,
  images_meta_data_array,
  dataJsonCacheKey,
  isEnableNGNiriImageSelectionAlgo,
  modelType,
}) => {
  const dataJson = cacheManager.get(dataJsonCacheKey);
  const jawsObject = {
    upper_jaw: dataJson.jaws.upper_jaw,
    lower_jaw: dataJson.jaws.lower_jaw,
  };

  const imageInfoSource = getImageInfoSource(isEnableNGNiriImageSelectionAlgo, jawsObject, jawName, modelType);

  for (let img_idx = 0; img_idx < currentActiveJaw.length; img_idx++) {
    const image_info = jawsObject[jawName].images[img_idx];
    if (!image_info) continue;

    const { timestamp, camera_id, scan_id, local_to_world_tx, caries_detection_score = 0 } = image_info[
      imageInfoSource
    ];

    // 1. Camera points and directions
    const { scan_to_cam_tx, camera_to_pixel, roi } = getCameraTransforms(dataJson, imageInfoSource, camera_id);
    const scanToCamTransform = new Matrix4().fromArray(JSON.parse(scan_to_cam_tx));
    const cameraToPixelTransform = new Matrix4().fromArray(JSON.parse(camera_to_pixel));
    const localToWorldTransform = new Matrix4().fromArray(JSON.parse(local_to_world_tx));
    const cam_to_abs_tx = get_cam_to_abs_tx(scanToCamTransform, localToWorldTransform);
    const camera_pt = new Vector3(0, 0, 0).applyMatrix4(cam_to_abs_tx);
    const camera_dir = lin_mult(new Vector3(0, 0, 1), cam_to_abs_tx);
    const imagesCenter = getImageCenter(camera_to_pixel);

    // 2. Projection of camera to surface
    const intersect = getRayIntersectCameraToModelSurface(camera_pt, camera_dir, mesh);

    let was_cam_projected = false;
    let img_cen_on_surf_pt = camera_pt;
    let dist_from_cam_to_surf = Number.MAX_VALUE;

    if (intersect) {
      was_cam_projected = true;
      img_cen_on_surf_pt = new Vector3().addVectors(camera_pt, camera_dir.multiplyScalar(intersect.distance));

      // used in rail algo only
      const min_dist_from_surface = mesh.geometry.boundsTree.closestPointToPoint(camera_pt).distance;
      dist_from_cam_to_surf = min_dist_from_surface;
    }

    const images_meta_data = {
      // 1. Camera points and directions
      camera_pt,
      camera_dir,

      // 2. Projection of camera to surface
      was_cam_projected,
      img_cen_on_surf_pt,
      dist_from_cam_to_surf,
      cam_to_abs_tx,
      is_visible: false,
      scan_role: jawName,
      timestamp: timestamp,
      camera_id: camera_id,
      scan_id: scan_id,
      rect_of_image: new Rect(roi),
      caries_detection_score,
      scan_to_cam_tx: scanToCamTransform,
      camera_to_pixel: cameraToPixelTransform,
      imagesCenter,
    };

    images_meta_data_array.push(images_meta_data);
  }

  return images_meta_data_array;
};

/**
 * TODO should refactor this fucntion to match the implementation on scaner side (C++)
 */
export const get_2d_point_in_image_px = (intersect, photoObj, isuminaBestScoreAlgorithmAvaliable = false) => {
  const point = intersect.point;
  const item = isuminaBestScoreAlgorithmAvaliable
    ? photoObj.niri || photoObj.color
    : photoObj.color || photoObj.niri || {};
  const worldToCam = item.worldToCamMatrix;

  if (!worldToCam) {
    return new Vector3(0, 0, 0);
  }
  const screenPoint = point.clone();
  screenPoint.applyMatrix4(worldToCam);
  screenPoint.set(screenPoint.x / screenPoint.z, screenPoint.y / screenPoint.z, 0);
  return screenPoint;
};

export const getRayIntersectCameraToModelSurface = (origin, direction, mesh) => {
  const rayCaster = new Raycaster(origin, direction.normalize(), 0, Infinity);
  rayCaster.firstHitOnly = true;
  const intersects = rayCaster.intersectObjects([mesh]);

  if (intersects && intersects.length > 0) {
    const intersect = intersects[0];
    return intersect;
  }

  return null;
};

export const getIsProjectedPointOnSurface = throttle((point, pointInsideSurface, mesh) => {
  let distance = Number.MAX_VALUE;
  const screenPoint = point.clone();
  const rayCaster = new Raycaster(pointInsideSurface, screenPoint.normalize(), 0, Infinity);
  rayCaster.firstHitOnly = true;

  const intersects = rayCaster.intersectObjects([mesh]);
  if (intersects && intersects.length > 0) {
    distance = intersects[0].distance;
  }
  if (distance > max_distance_to_selected_pt_mm) {
    return point;
  } else {
    return pointInsideSurface;
  }
}, utils.getValueByBrowser());

export const calculatePointInsideSurface = (intersectPoint, ray) => {
  cache.pointInsideSurface = cache.pointInsideSurface
    .subVectors(intersectPoint, ray.origin)
    .multiplyScalar(1 + 5 / cache.pointInsideSurface.length())
    .add(ray.origin);
};

export const calculateIntersectPointForImageSelection = (eventOrigin, intersectPoint, ray, mesh) => {
  const { EVENT_ORIGINS } = toolsEvents;

  if (eventOrigin === EVENT_ORIGINS.LOUPE_DRAG) {
    calculatePointInsideSurface(intersectPoint, ray);
    return intersectPoint;
  } else if (eventOrigin === EVENT_ORIGINS.MODEL_ROTATION) {
    return getIsProjectedPointOnSurface(intersectPoint, cache.pointInsideSurface, mesh);
  } else {
    logger
      .error('error')
      .data({ module: 'lumina-scanner-type.logic', errorMessage: 'Invalid eventOrigin' })
      .to(['analytics', 'host'])
      .end();
    return null;
  }
};

export const onImageSelectedUpdateRotation = (
  selected_image,
  camMatrixWorldInverse,
  luminaImageMetaData,
  viewer360Align2DImages
) => {
  // 1. Handle invalid_image_index
  const { img_idx, image, p2 } = selected_image;
  const imageMetadata = luminaImageMetaData[img_idx];
  const { cam_to_abs_tx, scan_to_cam_tx, camera_to_pixel } = imageMetadata;

  // 2. Calculate rotation angle and set image cache
  if (img_idx !== -1) {
    const cam_to_ui_tx = getCamToUiTx(camMatrixWorldInverse, cam_to_abs_tx);
    const rotation = calculateRotationAngle(cam_to_ui_tx, scan_to_cam_tx, viewer360Align2DImages);

    image.rotation = rotation || 0;
    image.selected_pt_on_image = p2;
    image.originalImageSize = { width: camera_to_pixel.elements[2] * 2, height: camera_to_pixel.elements[5] * 2 };
    image.shouldTransform = Math.abs(Math.abs(cache.prevRotation) - Math.abs(rotation)) < max_image_rotation_threshold;
    cache.prevRotation = rotation;
  }

  return image;
};

export const calculateRotationAngle = (cam_to_ui_tx, scan_to_cam_tx, viewer360Align2DImages) => {
  let rotation_angle = 0;
  let rotation_PI_axis = null;

  // TODO remove the condition when we will get the correct scan_to_cam_tx from NG scanner (should be identity matrix)
  if (scan_to_cam_tx.elements[0] === new Matrix4().elements[0]) {
    rotation_PI_axis = new Matrix4().makeRotationX(Math.PI);
  } else {
    rotation_PI_axis = new Matrix4().makeRotationY(Math.PI);
  }

  const cam_to_ui_tx_x_rotated = new Matrix4().multiplyMatrices(rotation_PI_axis, cam_to_ui_tx);

  const { elements } = new Matrix4().copy(cam_to_ui_tx_x_rotated).transpose();

  let x_axis_projection = new Vector3(elements[0], elements[4], 0);

  x_axis_projection = x_axis_projection.divideScalar(x_axis_projection.length());

  const projection_x = x_axis_projection.x;
  const projection_y = x_axis_projection.y;

  if (Math.abs(projection_x) > Math.abs(projection_y)) {
    rotation_angle = projection_x < 0 ? degreValues.e_180_deg : degreValues.e_0_deg;
  } else {
    rotation_angle = projection_y < 0 ? degreValues.e_270_deg : degreValues.e_90_deg;
  }

  if (viewer360Align2DImages) {
    let rotationDegrees = MathUtils.radToDeg(Math.acos(projection_x));
    return projection_y > 0 ? rotationDegrees : -rotationDegrees;
  }

  return rotation_angle;
};
