import React from 'react';
import { get, keys, cloneDeep } from 'lodash';
import setupSDK from '@web-3d-tool/sdk';
import { eventBus, defaultPluginEvents, utils, settingsManager, globalEventsKeys } from '@web-3d-tool/shared-logic';
import * as configValues from '@web-3d-tool/shared-logic/src/constants/configurationValues.constants';
import { Zones } from '@web-3d-tool/shared-logic/src/constants/zones.constants';
import { lazyLoadDynamicPlugins } from '@web-3d-tool/plugins';
import * as AT from '../../actionTypes';
import createMiddleware from './../../../middlewareHelper';
import { setPluginViews, setPluginsZones } from './../shell/shell.actions';

export let globalPlugins = {};
export let globalEvents = {};

//******************************
const feature = AT.PLUGINS;
//******************************

export const goThroughOverride = async ({ action, dispatch, getState }) => {};

export const middleware = async ({ action, dispatch, getState }) => {
  const { payload, type } = action;

  switch (type) {
    case AT.CHANGE_PLUGIN_ZONES:
      {
        const { pluginId, destinationZoneId } = payload;
        const zones = getState().shell.pluginsZones;
        const destinationZoneIndex = zones.findIndex((zone) => zone.id === destinationZoneId);
        const currentZoneIndex = zones.findIndex((zone) =>
          zone.props.children.some((component) => component.key === pluginId)
        );
        if (currentZoneIndex === destinationZoneIndex) return;

        const destinationZone = zones[destinationZoneIndex];
        const destinationsplugins = destinationZone.props.children;
        const currentZone = zones[currentZoneIndex];
        const currentZoneplugins = currentZone.props.children;
        currentZone.props.children = currentZoneplugins.filter((component) => component.key !== pluginId);
        const pluginComponent = globalPlugins[pluginId];

        //this is for putting the plugin in the right order of the preset if exist such order.
        const pluginIndexInPreset = zones[destinationZoneIndex].plugins.findIndex((plugin) => plugin.id === pluginId);
        if (pluginIndexInPreset >= 0) {
          destinationsplugins.splice(pluginIndexInPreset, 0, <pluginComponent.control key={pluginId} />);
        } else {
          destinationsplugins.push(<pluginComponent.control key={pluginId} />);
        }

        dispatch(setPluginsZones({ zones }));
      }
      break;

    case AT.LOAD_PLUGINS:
      {
        const { initiatingPlugin } = payload;
        const zones = cloneDeep(getState().shell.appPreset.zones);
        const arr = [];

        zones.forEach((zone) => {
          zone.plugins.forEach((plugin) => {
            const { implementation, id: pluginId } = plugin;

            if (implementation) {
              let pluginInstance;
              if (pluginId === initiatingPlugin) {
                pluginInstance = globalPlugins[pluginId];
              } else {
                const sdk = setupSDK({ pluginId, dispatch, getState });
                pluginInstance = implementation(sdk);
                globalPlugins[pluginId] = pluginInstance;
              }

              zone.props.children = zone.props.children || [];

              if (pluginInstance.control) {
                zone.props.children.push(<pluginInstance.control key={pluginInstance.id} />);
              }
            }
          });
        });

        arr.push(setPluginsZones({ zones }));

        dispatch(arr);

        eventBus.raiseEvent(globalEventsKeys.PLUGINS_LOADED);
      }
      break;

    case AT.EXTEND_ZONES_PLUGINS:
      {
        const arr = [];
        const { presets } = payload;
        const zones = getState().shell.pluginsZones;

        const presetId =
          getState().shell.presetId ||
          (utils.getIs360HubEnabled() ? '360' : settingsManager.getConfigValue(configValues.mode));

        await (async () => {
          return new Promise((resolve) => {
            presets.forEach(async ({ pluginIds, zoneName = Zones.TOOLS_MAIN }, index) => {
              const zone = zones.find((zone) => zone.id === zoneName);
              const presetPluginsObj = Object.assign({}, ...pluginIds);
              const ids = Object.keys(presetPluginsObj);
              const { resolvedDynamicPlugins, mainToolsOrder } = await lazyLoadDynamicPlugins(presetId, ids);

              resolvedDynamicPlugins.forEach((plugin) => {
                const pluginClone = cloneDeep(plugin);
                const { implementation, id: pluginId } = pluginClone;

                pluginClone.id = `${pluginClone.id}_${presetPluginsObj[pluginClone.id]}`;

                const index = mainToolsOrder.findIndex((pluginId) => pluginId === pluginClone.id);

                index >= 0 ? zone.plugins.push(pluginClone) : zone.plugins.splice(index, 0, pluginClone);

                if (implementation) {
                  let pluginInstance = pluginId === pluginClone.id && globalPlugins[pluginId];
                  if (!pluginInstance) {
                    const sdk = setupSDK({ pluginId: pluginClone.id, dispatch, getState });
                    pluginInstance = implementation(sdk);
                    globalPlugins[pluginClone.id] = pluginInstance;
                  }

                  zone.props.children = zone.props.children || [];

                  if (pluginInstance.control) {
                    index >= 0
                      ? zone.props.children.splice(index, 0, <pluginInstance.control key={pluginInstance.id} />)
                      : zone.props.children.push(<pluginInstance.control key={pluginInstance.id} />);
                  }
                }
              });

              index === presets.length - 1 && resolve();
            });
          });
        })();

        arr.push(setPluginsZones({ zones }));

        dispatch(arr);

        eventBus.raiseEvent(globalEventsKeys.REFRESH_TOOLS_MAPPER, {});
      }
      break;

    case AT.CLEAR_PLUGINS:
      {
        const { initiatingPlugin } = payload;

        keys(globalPlugins).forEach((id) => {
          if (id !== initiatingPlugin) {
            eventBus.raiseEvent(`${id}.${defaultPluginEvents.DESTROY}`);
            delete globalPlugins[id];
          }
        });
      }

      break;

    case AT.ADD_PLUGIN_VIEW:
      {
        const { id, view } = payload;
        const views = view || get(globalPlugins, `${id}.view`);
        dispatch(setPluginViews({ id, views }));
      }
      break;

    case AT.REMOVE_PLUGIN_VIEW:
      {
        const { id } = payload;
        const views = [];
        dispatch(setPluginViews({ id, views }));
      }
      break;

    case AT.CLEAR_AND_DESTROY_PLUGINS:
      {
        const { presets } = payload;

        const zones = getState().shell.pluginsZones;

        presets.forEach(({ pluginIds, zoneName = Zones.TOOLS_MAIN }) => {
          const zone = zones.find((zone) => zone.id === zoneName);
          const pluginsToRemove = zone.plugins.filter((plugin) =>
            Object.values(Object.assign({}, ...pluginIds)).includes(plugin.id)
          );

          zone &&
            pluginsToRemove.forEach(({ id }) => {
              eventBus.raiseEvent(`${id}.${defaultPluginEvents.DESTROY}`);
              delete globalPlugins[id];
              const views = [];
              dispatch(setPluginViews({ id, views }));
              zone.plugins = zone.plugins.filter((plugin) => plugin.id !== id);
              zone.props.children = zone.props.children.filter((prop) => prop.key !== id);
            });
        });
      }
      break;

    // default:
    //no default
  }
};

export default createMiddleware({ feature, goThroughOverride })(middleware);
