import { get, set } from 'lodash';

window.appCache = {};

const cacheManager = {
  get: (key) => {
    return get(window.appCache, key);
  },

  set: (key, value) => {
    set(window.appCache, key, value);
  },
};

const cacheKeys = {
  MATERIALS: 'materials',
  MODEL: 'model',
  VISIBILITY_OBJECT: 'visibilityObject',
  NIRI_DATA: 'niriData',
  PRESET: 'preset',
  THREE_OBJECTS: 'threeObjects',
  CONFIGURATION: 'configuration',
  DATA_JSON: 'data_json',
  IMAGES_META_DATA: 'images_meta_data',
  ZIPPED_DATA: 'zipped_data',
  JAW_STATE: 'jaw_state',
  SELECTED_ORDER_DATE: 'SELECTED_ORDER_DATE',
  AUTH_TOKEN: 'AUTH_TOKEN',
  APP_SETTINGS: 'APP_SETTINGS',
  SCENES_DIMENSIONS: 'SCENES_DIMENSIONS',
  CANDIDATE_GRID: 'CANDIDATE_GRID',
  WASM_MODULE: 'WASM_MODULE',

  // compare events
  COMPARE_DATA_JSON: 'compare_data_json',
  COMPARE_VISIBILITY_OBJECT: 'compare_VisibilityObject',
  COMPARE_NIRI_DATA: 'compare_niri_data',
  COMPARE_MATERIALS: 'compare_materials',
  COMPARE_MODEL: 'compare_model',
  COMPARE_IMAGES_META_DATA: 'compare_images_meta_data',
};

export { cacheManager, cacheKeys };
