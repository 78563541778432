import * as AT from '../../actionTypes';

export const loadPlugins = ({ initiatingPlugin }) => {
  return {
    type: AT.LOAD_PLUGINS,
    payload: { initiatingPlugin },
    meta: {
      feature: AT.PLUGINS,
    },
  };
};

export const loadExtendZonesPlugins = ({ presets }) => {
  return {
    type: AT.EXTEND_ZONES_PLUGINS,
    payload: { presets },
    meta: {
      feature: AT.PLUGINS,
    },
  };
};

export const clearPlugins = ({ initiatingPlugin }) => {
  return {
    type: AT.CLEAR_PLUGINS,
    payload: { initiatingPlugin },
    meta: {
      feature: AT.PLUGINS,
    },
  };
};
