export const Environments = {
  EUP: 'eup',
  SCANNER: 'scanner',
  AOHS: 'AOHS',
};

export const AOHS_Status = {
  CLEAR_MODE: 'CLEAR_MODE',
  CONDITION_MODE: 'CONDITION_MODE',
  NO_AOHS_MODE: 'NO_AOHS_MODE',
};

export const EnvironmentParams = {
  NIRI_ASSET_REFFERENCE_TYPE_NUMBER: '15',
};

export const ScannerEnvironmentURLs = {
  host: 'https://localhost:8080',
  dev_host: window.location.origin,
};
export const automationTestMode = 'automationTestMode';
