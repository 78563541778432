import * as THREE from 'three';
import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { extend, useThree } from 'react-three-fiber';
import PropTypes from 'prop-types';

import { LineExtended } from './lines/LineExtended.js';
import { LineMaterial } from './lines/LineMaterial.js';
import { LineGeometry } from './lines/LineGeometry.js';

extend({ LineExtended, LineMaterial, LineGeometry });

const MarginLine = (props) => {
  const { geometry, bite } = props;
  const { size } = useThree();
  const ref = useRef();
  const shouldUpdatePosition = useMemo(
    () => !bite.active || (bite.active && bite.previousTransformationInverseMatrix),
    [bite]
  );

  useLayoutEffect(() => {
    const positions = geometry.attributes.position.array;
    ref.current.setPositions(positions);
    // eslint-disable-next-line
  }, [shouldUpdatePosition]);

  return (
    <lineExtended>
      <lineGeometry attach="geometry" ref={ref} />
      <lineMaterial attach="material" color="red" linewidth={3} resolution={[size.width, size.height]} />
    </lineExtended>
  );
};

MarginLine.propTypes = {
  /**
   * positions to render as a line
   */
  positions: PropTypes.arrayOf(PropTypes.instanceOf(THREE.Vector3)).isRequired,
};

export default MarginLine;
