import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import stylesNo360 from './ImageFramesContainer.module.css';
import styles360 from './ImageFramesContainer360.module.css';

const EnlargeFrameButton = (props) => {
  const { type, is360, index, handleEnlarge, enlargedImage, viewer360Align2DImages } = props;

  const styles = is360 ? styles360 : stylesNo360;

  return (
    <div
      className={is360 && viewer360Align2DImages ? styles.enlarge_toggle_roation : styles.enlarge_toggle}
      onClick={() => {
        handleEnlarge(type, viewer360Align2DImages);
      }}
      data-test-id={`enlarge-${index}`}
      data-icon={enlargedImage ? 'shrink' : 'enlarge'}
      id={`enlarge-${index}`}
    />
  );
};

EnlargeFrameButton.defaultProps = {
  is360: false,
  handleEnlarge: noop,
};

EnlargeFrameButton.propTypes = {
  /**
   * sourceArr index
   */
  index: PropTypes.number,
  /**
   * image type niri / color
   */
  type: PropTypes.string,
  /**
   * viewer mode
   */
  is360: PropTypes.bool,
  /**
   * enlarge buton handler function
   */
  handleEnlarge: PropTypes.func,
  /**
   * is image enlarged
   */
  enlargedImage: PropTypes.bool,
};

export default EnlargeFrameButton;
