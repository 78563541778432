import logger from '../logger';
import validateDataFromHost from './data-validator';
import { hostEventsErrors } from '../event-bus';

let messageCallback = null;
export const postMessageManager = {
  init(callback) {
    messageCallback = callback;
    this.bindEvents();
  },
  destroy() {
    this.unbindEvents();
  },
  bindEvents() {
    window.addEventListener('message', this.handleIncomingMessage);

    logger
      .debug('listening to post message')
      .to('host')
      .data({ module: 'post-message-manager' })
      .end();
  },
  unbindEvents() {
    window.removeEventListener('message', this.handleIncomingMessage);
  },
  handleIncomingMessage: (event) => {
    const { data: message } = event;
    const isDataValid = validateDataFromHost(message);

    if (!isDataValid) message.data = hostEventsErrors.DATA_IS_NOT_VALID;

    messageCallback(message);
  },
  send: (message) => {
    window.parent.postMessage(message, '*');

    logger
      .debug('sending post message')
      .to('host')
      .data({ message, module: 'post-message-manager' })
      .end();
  },
};
