const Colors = {
  debug: 'color: green',
  info: 'color: blue',
  warn: 'color: yellow',
  error: 'color: red',
};

const SeverityScale = {
  debug: 1,
  info: 2,
  warn: 3,
  error: 4,
};

const _console = console;

const logic = {
  loggerLogic: (config, { to = {}, data = {}, message = '', severity = 'info' }) => {
    const { module = 'unknown', ...additionalData } = data;
    delete data.module;
    const { analytics = false, host = false, console = true } = to;
    const { appId, appVersion } = config;

    analytics && logic.handleAnalytics({ appId, appVersion, module, message, data: additionalData });
    host && logic.handleHostLogs({ appId, appVersion, module, message, data: additionalData });
    console && logic.handleConsole({ severity, module, message, data });
    !analytics && !host && !console && logic.handleDefault({ severity, module, message, data });
  },

  handleAnalytics: ({ appId, appVersion, module, message, data }) => {
    if (process.env.NODE_ENV === 'production') {
      if (!Object.entries(data).length) {
        window.ga('send', {
          hitType: 'event',
          eventCategory: module,
          eventAction: message,
        });
      } else {
        for (const key in data) {
          window.ga('send', {
            hitType: 'event',
            eventCategory: module,
            eventAction: `${message}, [${key}]: ${data[key]}`,
          });
        }
      }
    }
  },

  handleHostLogs: ({ appVersion, module, message, data, severity }) => {
    const msg = `[${appVersion}] (${module}) - ${message}`;
    _console.log(msg);

    if (process.env.NODE_ENV === 'development') {
      logic.handleConsole({ appVersion, module, message, data, severity });
    }
  },

  handleConsole: ({ severity, module, message, data }) => {
    const threshold = SeverityScale[process.env.REACT_APP_CONSOLE_LOGGER_THRESHOLD];
    if (SeverityScale[severity] >= threshold) {
      const consoleMessage = `%c[${severity}]%c (${module})%c ${message}`;
      _console.log(consoleMessage, Colors[severity], 'color:#444; font-weight:bold', 'color:#444', data);
    }
  },

  handleDefault: ({ severity, module, message, data }) => {
    _console.warn(`[logger] ~Unknown destination~ [${severity}] (${module}) ${message}`, data);
  },

  getConfig: () => ({
    appVersion: process.env.APP_VERSION,
    appId: 'web-3d-tool',
  }),
};

export default logic;
