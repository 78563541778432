import { isUndefined } from 'lodash';
import { Environments } from '../constants/environment.constants';
import { utils } from '../utils/index';
import { scannerVersionManager } from '../scanner-version-manager/scanner-version-manager';
import { cacheKeys, cacheManager } from '../cache-manager';
import { scannerMappingKeys } from '../constants/scannerContext.constants';
import logger from '../logger';
import * as configValues from '../constants/configurationValues.constants';
import storeCommunicationService from '../store-communication-service/store-communication.service';

const calcMode = (modeParameter) => {
  let mode = !isUndefined(modeParameter) ? modeParameter.toLowerCase() : modeParameter;
  if (mode !== 'ortho' && mode !== 'resto') {
    if (isUndefined(mode)) {
      logger
        .warn('Query parameter "mode" does not exists. Analyzing case type by url')
        .data({ module: 'settingsManager' })
        .end();
    } else {
      logger
        .warn('Query parameter "mode" does not recognized. Analyzing case type by url')
        .data({ module: 'settingsManager' })
        .end();
    }
    const { pathname } = window.location;
    mode = pathname.toLowerCase().includes('itero') ? 'resto' : 'ortho';
  }

  return mode;
};

const getConfigValueFromContext = (value) => {
  let sessionValue = null;
  const env = utils.getEnv();
  const scannerVersion = scannerVersionManager.getScannerVersion();
  const { v23C } = scannerVersionManager.scannerAssetsVersions;

  const scannerMappingValue =
    scannerMappingKeys[value] || Object.values(scannerMappingKeys).find((v) => v.toLowerCase() === value.toLowerCase());

  if (
    (env === Environments.AOHS && scannerMappingValue) ||
    (env === Environments.SCANNER && scannerVersion === v23C && scannerMappingValue)
  ) {
    const { context: sessionInfo } = storeCommunicationService.getStore();
    sessionValue = sessionInfo && sessionInfo[scannerMappingValue];
  }
  return sessionValue;
};

export const settingsManager = {
  getConfiguration: () => {
    const urlParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(urlParams);
  },

  init: () => {
    const isInitialized = cacheManager.get(cacheKeys.CONFIGURATION);
    if (!isInitialized) {
      const configuration = settingsManager.getConfiguration();
      configuration[configValues.mode] = calcMode(configuration[configValues.mode]);
      if (configuration[configValues.isEVxEnabled] === 'true') {
        configuration[configValues.isIOCEnabled] = 'true';
        logger
          .warn('isIOCEnabled changed to "true" because isEVxEnabled = "true"')
          .data({ module: 'settingsManager' })
          .end();
      }

      const lowerCaseConfiguration = Object.keys(configuration).reduce((acc, key) => {
        acc[key.toLowerCase()] = configuration[key];
        return acc;
      }, {});
      cacheManager.set(cacheKeys.CONFIGURATION, lowerCaseConfiguration);

      logger
        .to('host')
        .info(`Configuration keys: \n ${JSON.stringify(lowerCaseConfiguration)}`)
        .data({ module: 'settings manager' })
        .end();
    }
  },

  getConfigValue: (value) => {
    const valueFromContext = getConfigValueFromContext(value);

    if (valueFromContext) {
      return valueFromContext;
    } else {
      let config = cacheManager.get(cacheKeys.CONFIGURATION);
      if (!config) {
        settingsManager.init();
        config = cacheManager.get(cacheKeys.CONFIGURATION);
      }
      return config ? config[value.toLowerCase()] : undefined;
    }
  },
};
