import { get } from 'lodash';
import * as AT from '../../actionTypes';

const INIT_STATE = {
  model: null,
  compareModel: null,
  metadata: {
    upper_jaw: {
      visible: false,
      upper_jaw: {
        visible: false,
      },
    },
    lower_jaw: {
      visible: false,
      lower_jaw: {
        visible: false,
      },
    },
    textures: [],
    bite: {
      active: false,
      previousTransformationInverseMatrix: null,
    },
  },
  compareMetadata: {
    upper_jaw: {
      visible: false,
      upper_jaw: {
        visible: false,
      },
    },
    lower_jaw: {
      visible: false,
      lower_jaw: {
        visible: false,
      },
    },
    textures: [],
    bite: {
      active: false,
      previousTransformationInverseMatrix: null,
    },
  },
  isThreejsObjectsReady: false,
  stage: [],
  readonlyStage: [],
  resetCameraRotationOnUpdate: false,
  modelIsLoading: false,
};

const match = ({ state, action }) => {
  const { type, payload } = action;

  switch (type) {
    case AT.SET_MODEL_ID:
      return {
        ...state,
        model: { ...payload },
      };

    case AT.SET_COMPARE_MODEL_ID:
      return {
        ...state,
        compareModel: { ...payload },
      };

    case AT.SET_METADATA:
      return {
        ...state,
        metadata: { ...payload.metadata },
      };

    case AT.MODEL_COMPARE_ACTIVE:
      return {
        ...state,
        isModelCompareActive: payload.isModelCompareActive,
        isModelCompareInDifferentMode: payload.isModelCompareInDifferentMode,
      };

    case AT.SET_COMPARE_METADATA:
      return {
        ...state,
        compareMetadata: { ...payload.compareMetadata },
      };

    case AT.SET_TEXTURES:
      return {
        ...state,
        metadata: { ...state.metadata, textures: payload.textures },
      };

    case AT.SET_COMPARE_TEXTURES:
      return {
        ...state,
        compareMetadata: { ...state.compareMetadata, textures: payload.textures },
      };

    case AT.TOGGLE_SYNC:
      return {
        ...state,
        isModelSynced: payload.isModelSynced,
      };

    case AT.SET_RENDERING_STAGE:
      return {
        ...state,
        stage: payload.stage,
      };

    // This attribute is being used by the QA Automation team - to enable measurements of the camera position
    // We use readOnly attribute that doesn't effect nothing because every time we change the stage the model get re-rendered,
    // to avoid this issue we write the camera position to a new property
    case AT.SET_RENDERING_READONLY_STAGE:
      return {
        ...state,
        readonlyStage: payload.readonlyStage,
      };

    case AT.SET_IS_THREEJS_OBJECTS_READY:
      return {
        ...state,
        isThreejsObjectsReady: payload.isThreejsObjectsReady,
      };

    case AT.SET_READONLY_STAGE:
      return {
        ...state,
        readonlyStage: payload.readonlyStage,
      };
    case AT.SET_RESET_CAMERA_ROTATION_ON_UPDATE:
      const { resetCameraRotationOnUpdate } = payload;
      return {
        ...state,
        resetCameraRotationOnUpdate,
      };
    case AT.SET_STAGE: {
      const { stage } = payload;
      return {
        ...state,
        stage,
      };
    }
    case AT.SET_MODEL_IS_LOADING: {
      return {
        ...state,
        modelIsLoading: payload.loading,
      };
    }

    default:
      return state;
  }
};

const featureName = AT.RENDERER;
export default (state = INIT_STATE, action) => {
  const feature = get(action, 'meta.feature');
  return feature === featureName ? match({ state, action }) : state;
};
