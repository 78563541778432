import queryString from 'query-string';
import UAParser from 'ua-parser-js';
import { get, keys } from 'lodash';
import moment from 'moment';
import { NIRI, IOC } from '@web-3d-tool/shared-logic/src/constants/tools.constants';
import { NIRI_COMPARE, IOC_COMPARE } from '@web-3d-tool/shared-logic/src/constants/compare-tools.constants';
import { modelTypes } from '@web-3d-tool/shared-logic/src/constants/model.constants';
import * as configuration from '../constants/configurationValues.constants';
import { Environments } from '../constants/environment.constants';
import { settingsManager } from '../settings-manager';
import { cacheManager, cacheKeys } from '../cache-manager';
import supportedLocales from './supported-locales.json';
import storeCommunicationService from '../store-communication-service/store-communication.service';
import { scannerVersionManager } from '../scanner-version-manager/scanner-version-manager';

const userAgentParser = new UAParser();
const browser = userAgentParser.getBrowser();
const { ORIGIN } = modelTypes;

const getUrlParam = ({ url, param }) => {
  const urlToSearch = url || window.location.href;
  const arr = urlToSearch.split('?');
  const search = url ? (arr.length === 1 ? '' : arr[1]) : window.location.search;
  const paramsObj = queryString.parse(search);
  const lowerCaseParamObj = keys(paramsObj).reduce((acc, key) => {
    acc[key.toLowerCase()] = paramsObj[key];
    return acc;
  }, {});
  return lowerCaseParamObj[param.toLowerCase()];
};
const getSessionType = () => {
  const SessionType = settingsManager.getConfigValue(configuration.SessionType);
  return SessionType;
};
const getSessionId = () => {
  const SessionId = settingsManager.getConfigValue(configuration.SessionId);
  return SessionId;
};
const getIs360HubEnabled = () => {
  const env = getEnv();
  const layout360 = settingsManager.getConfigValue(configuration.layout360);
  return layout360 === 'true' || env === Environments.AOHS;
};

const getBrowser = () => browser;

/**
 * This function is using ua-parser-js as singlethon
 * It supposed to have a default rules object for each browser with the optimal throttle timing:
 * {
    ie: 200,
    edge: 200,
    chrome: 150,
    opera: 150,
    firefox: 150,
    safari: 120,
    default: 200
  }
 * and return the value according to the current browser
 * @param {Object} rules
 */
const getValueByBrowser = (customRules) => {
  const rules = customRules || {
    ie: 200,
    edge: 200,
    chrome: 150,
    opera: 150,
    firefox: 150,
    safari: 120,
    default: 200,
  };
  const browserName = browser.name.toLowerCase();
  return get(rules, browserName) || rules.default || null;
};

const getAppVersion = () => process.env.APP_VERSION;

const makeCancelablePromise = (promise) => {
  let hasCanceled = false;
  let hasSettled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then(
        (val) => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
        (error) => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
      )
      .finally(() => {
        hasSettled = true;
      });
  });

  return {
    promise: wrappedPromise,
    cancel() {
      if (!hasSettled) {
        hasCanceled = true;
      }

      return hasCanceled;
    },
  };
};

const getEnv = () => {
  let host = utils.getUrlParam({ param: 'host' });
  switch (true) {
    case host === 'eup':
      return Environments.EUP;
    case host === 'scanner':
      return Environments.SCANNER;
    case !host:
      return Environments.AOHS;
    default:
      // no default
      break;
  }
};

const calcWindowWidth = () =>
  window.innerWidth >= 1480 && window.innerWidth <= 1920 ? window.innerWidth : window.innerWidth > 1920 ? 1920 : 1480;

const formatDateAndTime = (dateString) => {
  const formattedDateAndTime = moment(dateString).format('D MMM YYYY,hh:mm a');
  const [formattedDate, formattedTime] = formattedDateAndTime.split(',');
  return { formattedDate, formattedTime };
};

const getIsScanOriginLumina = (modelType) => {
  const model = cacheManager.get(modelType || cacheKeys.MODEL);
  if (model) {
    const { originScanner } = model;
    return !!(originScanner && originScanner.toLowerCase().includes('lumina'));
  }
  return false;
};

const is5DPluginActiveIn360Hub = (sdk) => {
  const is360 = getIs360HubEnabled();
  const activatedPlugins = sdk.getActivatedPlugins();
  const isNiriIndicationOn = [NIRI.id, IOC.id, IOC_COMPARE.id, NIRI_COMPARE.id].some((id) =>
    activatedPlugins.includes(id)
  );
  return is360 && isNiriIndicationOn;
};

const getOrderId = () => {
  return settingsManager.getConfigValue(configuration.orderId);
};

const getCompanyId = () => {
  return settingsManager.getConfigValue(configuration.companyId);
};

const getDoctorId = () => {
  return settingsManager.getConfigValue(configuration.userId);
};

const getRxGuid = () => {
  return settingsManager.getConfigValue(configuration.rxGuid);
};

const getModelCaseType = (modelType = ORIGIN) => {
  const { caseType } = cacheManager.get(cacheKeys[modelType.toUpperCase()]) || {
    caseType: settingsManager.getConfigValue(configuration.mode) || 'ortho',
  };

  return caseType;
};

const getCompareModelCaseType = () => {
  const { caseType } = cacheManager.get(cacheKeys.COMPARE_MODEL);
  return caseType;
};

const isStandaloneMode = () => {
  const standaloneMode = settingsManager.getConfigValue(configuration.standaloneMode) === 'true';
  const isHostedInIframe = window.self !== window.top;
  const isScannerEnv = isScannerHostEnv();
  const isMIDCEnv = isEupHostEnv();
  const isAOHS = isAOHSEnv();

  switch (true) {
    case standaloneMode:
      return true;
    case isMIDCEnv && isHostedInIframe:
    case isScannerEnv && !isHostedInIframe:
    case isAOHS && isHostedInIframe:
      return false;
    default:
      return true;
  }
};

const isScannerHostEnv = () => {
  const host = utils.getEnv();
  const isInScannerContext = window.cefSharp;
  return host === Environments.SCANNER && !!isInScannerContext;
};

const isEupHostEnv = () => {
  const host = utils.getEnv();
  return host === Environments.EUP;
};

const isAOHSEnv = () => {
  const host = utils.getEnv();
  return host === Environments.AOHS;
};

const is22BScannerVersion = () => {
  const isScannerEnv = isScannerHostEnv();
  const currentScannerVersion = scannerVersionManager.getScannerVersion();
  const { v22B } = scannerVersionManager.scannerAssetsVersions;

  return isScannerEnv && currentScannerVersion === v22B;
};

const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

const getLocationParentObject = (sdk) => {
  const is360 = getIs360HubEnabled();
  if (is360) {
    const { currentExplorer } = sdk.getActiveExplorer();
    if (!currentExplorer) {
      return '360 Hub';
    } else {
      return currentExplorer;
    }
  } else {
    return '';
  }
};

const isMac = () => {
  return /Mac|iPod|iPhone|iPad/.test(navigator.userAgent);
};

const getLogonasId = () => {
  const { context: sessionInfo } = storeCommunicationService.getStore() || {};
  if (!sessionInfo) return '0';

  if (isEupHostEnv()) {
    const { loggedInUserId: logonasId } = sessionInfo;
    return logonasId ? logonasId.toString() : '0';
  } else {
    return sessionInfo && sessionInfo['userId'] ? sessionInfo['userId'].toString() : '0';
  }
};

const getAccessToken = () => {
  const { context: sessionInfo } = storeCommunicationService.getStore() || {};
  const { accessToken } = sessionInfo || {};
  return accessToken ? `Bearer ${accessToken}` : '';
};

const getLocale = () => {
  const localeParam = settingsManager.getConfigValue(configuration.locale);
  const supportedLocale = supportedLocales[localeParam ? localeParam.toLowerCase() : 'en-us'];
  return supportedLocale ? supportedLocale : 'en-US';
};

const getScannerPackageVersion = () => {
  return settingsManager.getConfigValue(configuration.ScannerPackageVersion);
};

const getCompareRowIndex = () => {
  const dateObj = cacheManager.get(cacheKeys.SELECTED_ORDER_DATE) || {};
  const compareRowIndex = new Date(dateObj.current) > new Date(dateObj.selected) ? 0 : 1;
  return compareRowIndex;
};

const getIsAutomationTestMode = () => {
  return settingsManager.getConfigValue(configuration.automationTestMode) === 'true';
};

export const utils = {
  getSessionId,
  getSessionType,
  getUrlParam,
  getIs360HubEnabled,
  getAppVersion,
  getValueByBrowser,
  getBrowser,
  makeCancelablePromise,
  getEnv,
  calcWindowWidth,
  formatDateAndTime,
  getIsScanOriginLumina,
  is5DPluginActiveIn360Hub,
  getOrderId,
  getCompanyId,
  getDoctorId,
  getRxGuid,
  isStandaloneMode,
  isValidUrl,
  getLocationParentObject,
  isMac,
  isScannerHostEnv,
  isEupHostEnv,
  getModelCaseType,
  getLogonasId,
  getLocale,
  getScannerPackageVersion,
  getCompareRowIndex,
  getCompareModelCaseType,
  isAOHSEnv,
  getAccessToken,
  is22BScannerVersion,
  getIsAutomationTestMode,
};
