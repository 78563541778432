import LocalizedStrings from 'react-localization';
import { logger, utils } from '@web-3d-tool/shared-logic';
import en from './translations/en-US.json';

export const strings = new LocalizedStrings({ en });

export const loadLanguage = async () => {
  const localization = utils.getLocale();
  localization &&
    import(`./translations/${localization}.json`)
      .then((newLang) => strings.setContent({ newLang }))
      .catch((err) =>
        logger
          .error(err)
          .data({ module: 'app shell' })
          .end()
      );
};

export const en_strings = new LocalizedStrings({ en });
