import React from 'react';
import { cacheManager, cacheKeys } from '@web-3d-tool/shared-logic';

export const MeshWithTextureMapping = (props) => {
  const { map, geometry, textureName, modelName, isLuminaScan } = props;
  const jawsPluginCurrentState = cacheManager.get(cacheKeys.JAW_STATE);

  if (!textureName.includes('texture_mapping')) {
    return null;
  }

  const RenderMeshWithTextureMapping = () => {
    return (
      <mesh geometry={geometry} name={modelName}>
        {isLuminaScan ? (
          <phongTextureMaterial
            name="PhongTextureMaterial"
            attach="material"
            texture={map}
            args={[map, jawsPluginCurrentState, props]}
            map={map}
            map-flipY={false}
          />
        ) : (
          <textureMaterial name="TextureMaterial" attach="material" texture={map} map={map} map-flipY={false} />
        )}
      </mesh>
    );
  };

  return <RenderMeshWithTextureMapping />;
};
