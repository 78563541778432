import const_params from '../const_params';
const { replacement_threshold } = const_params;
class Candidate {
  constructor(candidate_id, score = 0) {
    this.candidate_id = candidate_id;
    this.score = score;
  }
}

class CandidateGrid {
  constructor({ rows, cols, grid }) {
    if (grid) {
      this.rows = grid.rows;
      this.cols = grid.cols;
      this.cell_to_candidate = [...grid.cell_to_candidate];
      this.score = grid.score;
    } else {
      this.rows = rows;
      this.cols = cols;
      this.score = 0;
      this.cell_to_candidate = new Array(rows * cols).fill(undefined);
    }
  }

  set_cell_candidate({ row, col, candidate, enable_overwriting }) {
    this.setCellCandidateHelper(row * this.cols + col, candidate, enable_overwriting);
  }

  merge(candidate_grid, enable_overwriting) {
    if (this.get_rows() !== candidate_grid.get_rows() && this.get_cols() !== candidate_grid.get_cols()) {
      throw new Error('CandidateGrid::merge: mismatched grid sizes');
    }

    for (let candidate_index = 0; candidate_index < this.cell_to_candidate.length; ++candidate_index) {
      const replacement_candidate = candidate_grid.cell_to_candidate[candidate_index];
      if (replacement_candidate) {
        this.setCellCandidateHelper(candidate_index, replacement_candidate, enable_overwriting);
      }
    }
  }

  get_cell_candidate(row, col) {
    return this.cell_to_candidate[row * this.cols + col];
  }

  get_score() {
    return this.score;
  }

  get_rows() {
    return this.rows;
  }

  get_cols() {
    return this.cols;
  }

  setCellCandidateHelper(index, candidate, enableOverwriting) {
    const cell = this.cell_to_candidate[index];
    if (!cell) {
      this.cell_to_candidate[index] = candidate;
      this.score += candidate.score;
    } else if (
      (enableOverwriting && candidate.score > cell.score) ||
      (cell.score < replacement_threshold && candidate.score > replacement_threshold)
    ) {
      this.score -= cell.score;
      this.cell_to_candidate[index] = candidate;
      this.score += candidate.score;
    }
  }
}

export { CandidateGrid, Candidate };
