import { get, keys } from 'lodash';
import { CatmullRomCurve3, ExtrudeGeometry, LineBasicMaterial, Matrix4, Mesh, Shape, Vector3 } from 'three';
import { EXTRACTED_MODEL, ObjectsKeys } from '../../constants/model.constants';
import { utils } from '../../utils';
import { createMipmapTexture, createImageFromDataURL } from './texture';
import { extractFile } from '../../unzip-util';
import CTM from './ctm';
import CTMLoader from './ctm-loader';
import { logToTimber } from '../../timberLogger';
import logger from '../../logger';
import { downloadFile } from '../download-file';
import { Environments } from '../../constants/environment.constants';
import request from '../../api-service/apiService';
import { map } from '../../api-service/apiMap';

const { apiMapKeys } = map;

const MAX_PREPS_FOR_HD_TEXTURES = 10;

const ctmLoader = new CTMLoader();

const bitesCorrectOrdering = Object.freeze({
  additional_centric_bite: 2,
  right_lateral_bite: 3,
  left_lateral_bite: 4,
  protrusive_bite: 5,
  retrusive_bite: 6,
});

const getAdaIdFromPrepName = (prepName) => prepName.replace('ada', '');

const objectPromiseAll = async ({ promisesObj, spreadKey }) => {
  let resolvedObj = {};
  const keys = Object.keys(promisesObj);
  const promiseValues = Object.values(promisesObj);

  try {
    const promisesResult = await Promise.all(promiseValues);

    keys.forEach((key, i) => {
      if (key === spreadKey) {
        resolvedObj = { ...resolvedObj, ...promisesResult[i] };
      } else {
        resolvedObj[key] = promisesResult[i];
      }
    });
    return resolvedObj;
  } catch (error) {
    logger
      .info(error)
      .to(['host'])
      .data({ module: 'itr-fetcher.service.logic', getPrep: keys.join(',') })
      .end();

    return error;
  }
};

const parseMarginLineGeometry = (str) => {
  if (str === undefined) return null;

  const lines = str.split('\n');
  if (lines.length <= 0) return null;

  const numOfLines = parseInt(lines[0], 10);
  if (lines.length < numOfLines) return null;

  const pts = [];

  for (let i = 1; i <= numOfLines; i++) {
    const pointsArray = lines[i].split(' ');
    pts.push(new Vector3(Number(pointsArray[0]), Number(pointsArray[1]), Number(pointsArray[2])));
  }

  const curvesPath = new CatmullRomCurve3(pts);
  const extrudeSettings = {
    steps: numOfLines,
    bevelEnabled: false,
    extrudePath: curvesPath,
  };

  const shape = new Shape();
  const length = 0.03;

  shape.moveTo(0, 0);
  shape.lineTo(-length, -length);
  shape.lineTo(length, -length);
  shape.lineTo(length, length);
  shape.lineTo(-length, length);
  shape.lineTo(-length, -length);

  const geometry = new ExtrudeGeometry(shape, extrudeSettings);
  const marginLineMesh = new Mesh(geometry, new LineBasicMaterial());
  marginLineMesh.name = 'margin_line';
  return marginLineMesh;
};

const setMissingPreps = (metaData) => {
  const obj = {};
  for (let prepName in metaData.missing_preps) obj[prepName] = {};
  return obj;
};

const createOffsetLineGeometryForPrep = async (zippedModel, prepName, metadata, adaId) => {
  if (metadata.preps[prepName].offset_line === undefined || metadata.preps[prepName].offset_line.url === undefined)
    return null;

  const { url } = metadata.preps[prepName].offset_line;
  const str = await zippedModel.file(url).async('string');
  const marginLineGeometry = parseMarginLineGeometry(str);
  const { MarginLine } = ObjectsKeys.Preps[adaId];
  const geometry = await createGeometryFromCTMStream(marginLineGeometry);
  return { [MarginLine]: geometry };
};

const getPreps = async (metadata, zippedModel) => {
  let obj = {};
  if (!metadata.preps) {
    return obj;
  }

  const preparePrepsData = (metadata, prepName) => {
    return new Promise(async (resolve, reject) => {
      let prepObj = {};
      const { url } = metadata.preps[prepName];
      if (url === undefined) resolve({});

      const adaId = getAdaIdFromPrepName(prepName);

      prepObj = {
        ...prepObj,
        [prepName]: getGeometryFromZippedModel(zippedModel, url),
        marginLineObj: createOffsetLineGeometryForPrep(zippedModel, prepName, metadata, adaId),
      };

      const cover = get(metadata, `preps.${prepName}.ditch.cover`);
      if (cover) {
        const ditchCoverName = ObjectsKeys.Preps[adaId].DitchCover;
        const ditchCoverUrl = metadata.preps[prepName].ditch.cover.url;

        prepObj = {
          ...prepObj,
          [ditchCoverName]: getGeometryFromZippedModel(zippedModel, ditchCoverUrl),
        };
      }

      const ditchOuter = get(metadata, `preps.${prepName}.ditch.outer`);
      if (ditchOuter) {
        const ditchOuterName = ObjectsKeys.Preps[adaId].DitchOuter;
        const ditchOuterUrl = metadata.preps[prepName].ditch.outer.url;

        prepObj = {
          ...prepObj,
          [ditchOuterName]: getGeometryFromZippedModel(zippedModel, ditchOuterUrl),
        };
      }

      const ditchInner = get(metadata, `preps.${prepName}.ditch.inner`);
      if (ditchInner) {
        const ditchInnerName = ObjectsKeys.Preps[adaId].DitchInner;
        const ditchInnerUrl = metadata.preps[prepName].ditch.inner.url;

        prepObj = {
          ...prepObj,
          [ditchInnerName]: getGeometryFromZippedModel(zippedModel, ditchInnerUrl),
        };
      }

      const adjacent = get(metadata, `preps.${prepName}.adjacent`);
      if (adjacent) {
        const adjName = ObjectsKeys.Preps[adaId].Adjacent;
        const adjUrl = metadata.preps[prepName].adjacent.url;

        prepObj = {
          ...prepObj,
          [adjName]: getGeometryFromZippedModel(zippedModel, adjUrl),
        };
      }
      const objPromisesAll = await objectPromiseAll({ promisesObj: prepObj, spreadKey: 'marginLineObj' });

      if (objPromisesAll instanceof Error) {
        return reject(objPromisesAll);
      } else {
        prepObj = { ...objPromisesAll };
        return resolve(prepObj);
      }
    });
  };

  const prepsDataPromises = Object.keys(metadata.preps).map((prepName) => preparePrepsData(metadata, prepName));
  const resolvedPrepsData = await Promise.all(prepsDataPromises);

  for (let index = 0; index < resolvedPrepsData.length; index++) {
    obj = { ...obj, ...resolvedPrepsData[index] };
  }

  return obj;
};

export const getModelByMetadata = async (metadata, extractedModel, zippedModel) => {
  try {
    const model = { ...extractedModel };
    const [missingPreps, jaws, preps] = await Promise.all([
      setMissingPreps(metadata),
      getJaws(metadata, zippedModel),
      getPreps(metadata, zippedModel),
    ]);

    model.objects = { ...missingPreps, ...jaws, ...preps };
    model.multiBite = setMultibiteMatrix(metadata);
    return model;
  } catch (err) {
    return Promise.reject(err);
  }
};

const createGeometryFromCTMStream = (ctmStream) => {
  return new Promise((resolve, reject) => {
    if (ctmStream instanceof CTM.File) {
      return ctmLoader.createModel(ctmStream, (geometry) => {
        if (!isEmptyGeometry(geometry)) {
          resolve(geometry);
        }
      });
    } else if ('geometry' in ctmStream) {
      resolve(ctmStream);
    }
    resolve(null);
  });
};

const getMultibiteTransformations = (value) => {
  const transformationArry = JSON.parse(value);
  const transformationMatrix = new Matrix4().fromArray(transformationArry);
  const transformationInverseMatrix = new Matrix4().copy(transformationMatrix).invert();
  return {
    transformationMatrix,
    transformationInverseMatrix,
  };
};

export const setMultibiteMatrix = (metaData) => {
  let currentIndex = 2;

  const { multi_bite_trasformation } = metaData;
  if (!multi_bite_trasformation) {
    return { ...EXTRACTED_MODEL.multiBite };
  }

  const multibiteMatrixObject = {
    isAvailable: true,
    bites: {},
  };

  const keys = Object.keys(multi_bite_trasformation);

  keys.sort((a, b) => bitesCorrectOrdering[a] - bitesCorrectOrdering[b]);

  keys.forEach((key) => {
    const biteKey = key === 'value' ? 'additional_centric_bite' : key;
    const value = multi_bite_trasformation[key];
    const multibiteTransformation = getMultibiteTransformations(value);
    multibiteMatrixObject.bites[biteKey] = {
      biteIndex: currentIndex,
      ...multibiteTransformation,
    };

    currentIndex += 1;
  });

  return multibiteMatrixObject;
};

const getGeometryFromZippedModel = async (zippedModel, ctmUrl) => {
  try {
    const ctmStream = await extractFile(zippedModel, ctmUrl, 'uint8array');
    const ctmFile = new CTM.File(new CTM.Stream(ctmStream));
    const geometry = await createGeometryFromCTMStream(ctmFile);
    return geometry;
  } catch (err) {
    return Promise.reject(err);
  } finally {
    logger.timeEnd(`extracting ${ctmUrl} type ${'uint8array'}`);
  }
};

const getJaws = async (metadataObject, zippedModel) => {
  const { jaws } = metadataObject;
  let obj = {};
  if (!jaws) return {};

  Object.entries(jaws).forEach(([jawName, jaw]) => {
    obj[jawName] = getGeometryFromZippedModel(zippedModel, jaw.url);
  });

  return await objectPromiseAll({ promisesObj: obj });
};

const isEmptyGeometry = (geometry) => {
  if (geometry === undefined) return true;

  geometry.computeBoundingBox();
  const { boundingBox } = geometry;
  if (!boundingBox) return true;

  const zeroVector = new Vector3();
  return boundingBox.min.equals(zeroVector) && boundingBox.max.equals(zeroVector);
};

const getMipmapOptions = (isHeavyModel) => {
  const maxTextureSizeOptions = { maxTextureWidth: 2048, maxTextureHeight: 2048 };

  // Safari on Mac/iPad crashes with textures above 2048 pixels.
  const browserName = utils.getBrowser().name;
  const isSafari = browserName === 'Safari';

  return { ...((isSafari || isHeavyModel) && maxTextureSizeOptions) };
};

export const getModelTextures = async (zippedModel, metaDataObj) => {
  const { jaws, preps } = metaDataObj;
  const textures = [];

  const jawsTexturePaths = keys(jaws).map((jaw) => `jaws.${jaw}.texture`);
  const prepsTexturePaths = keys(preps).map((prep) => `preps.${prep}.texture`);
  const adjacentTexturePaths = keys(preps)
    .filter((prep) => metaDataObj.preps[prep].adjacent)
    .map((prep) => `preps.${prep}.adjacent.texture`);
  const coverTexturePaths = keys(preps)
    .filter((prep) => metaDataObj.preps[prep].cover)
    .map((prep) => `preps.${prep}.cover.texture`);

  const isHeavyModel = prepsTexturePaths.length > MAX_PREPS_FOR_HD_TEXTURES;

  const texturePaths = [...jawsTexturePaths, ...prepsTexturePaths, ...adjacentTexturePaths, ...coverTexturePaths];

  const mipmapOptions = getMipmapOptions(isHeavyModel);

  try {
    const extractFilePromises = texturePaths
      .map((texturePath) => {
        const textureImageUrl = get(metaDataObj, texturePath);
        return textureImageUrl && extractFile(zippedModel, textureImageUrl, 'base64');
      })
      .filter((textureImage) => textureImage);

    const base64Textures = (await Promise.allSettled(extractFilePromises)).filter(
      ({ status }) => status === 'fulfilled'
    );

    const imagesFromDataUrl = base64Textures.map(({ value: base64Texture }, index) => {
      const texturePath = texturePaths[index];
      const textureImageUrl = get(metaDataObj, texturePath);
      const imageType = textureImageUrl.split('.')[1];
      const imageDataURL = `data:image/${imageType};base64,${base64Texture}`;
      return createImageFromDataURL(imageDataURL);
    });
    const images = (await Promise.allSettled(imagesFromDataUrl)).filter(({ status }) => status === 'fulfilled');

    const mitMapTexturesPromises = images.map(({ value: image }) => {
      return createMipmapTexture({ img: image, options: mipmapOptions });
    });
    const mitMapTextures = (await Promise.allSettled(mitMapTexturesPromises)).filter(
      ({ status }) => status === 'fulfilled'
    );

    for (let i = 0; i < texturePaths.length; i++) {
      const texturePath = texturePaths[i];
      const textureImageUrl = get(metaDataObj, texturePath);

      if (!textureImageUrl) {
        continue;
      }

      const texture = mitMapTextures[i].value;
      const modelName = texturePath
        .split('.')
        .slice(1, -1)
        .join('_');
      texture.name = `${modelName}_texture_mapping`;

      textures.push(texture);
    }
  } catch (error) {
    logger
      .info(error)
      .to(['host'])
      .data({ module: 'itr-fetcher.service.logic', material: 'getModelTextures' })
      .end();
  }
  return textures;
};

const isItrExists = async (orderId) => {
  const res = await request({
    selector: apiMapKeys('IsItrFileExists'),
    queryParams: {
      orderId,
    },
  });
  return res.json();
};

export const waitForItrToBeCreated = async (environment, requestParams) => {
  const { queryParams } = requestParams || {};
  return environment === Environments.EUP ? await eupPolling({ queryParams }) : await scannerPolling({ requestParams });
};

export const waitForNiriToBeCreated = async (requestParams, progressCB) => {
  return await scannerPolling({ requestParams, progressCB });
};

export const eupPolling = ({ queryParams }) => {
  let attempts = 0;
  const { orderId } = queryParams;
  const interval = 5000;
  const maxAttempts = 12;

  const executeItrCreationPoll = async (resolve, reject) => {
    const isItrCreated = await isItrExists(orderId);
    attempts++;

    const logMessage = `Checking for Itr file existence on EUP env, attempt number #${attempts} after ${(attempts *
      interval) /
      1000} seconds`;
    logToTimber({
      timberData: {
        action: 'downloading itr',
        module: 'itr-fetcher',
        type: 'object',
        actor: 'System',
        value: logMessage,
      },
    });

    if (isItrCreated) {
      return resolve(isItrCreated);
    } else if (attempts === maxAttempts) {
      const error = new Error('Exceeded max attempts to fetch itr file');
      error.name = 'max_attempts_exceeded';
      return reject(error);
    } else {
      setTimeout(executeItrCreationPoll, interval, resolve, reject);
    }
  };

  return new Promise(executeItrCreationPoll);
};

export const scannerPolling = ({ requestParams, progressCB }) => {
  let attempts = 0;
  let isTimeout = false;

  setTimeout(() => {
    isTimeout = true;
  }, 360000);

  const executeItrCreationPoll = async (resolve, reject) => {
    const interval = attempts < 15 ? 3000 : 15000;

    const response = await downloadFile({ ...requestParams, progressCB: progressCB || (() => ({})) });
    attempts++;

    const logMessage = `Checking for itr file existence on Scanner env, attempt number #${attempts} after ${(attempts *
      interval) /
      1000} seconds`;
    logToTimber({
      timberData: {
        action: `downloading itr`,
        module: 'itr-fetcher',
        type: 'object',
        actor: 'System',
        value: logMessage,
      },
    });

    if (response && response.status && response.status === 200) {
      return resolve(response);
    } else if (isTimeout) {
      const error = new Error(`Exceeded 6 minute timeout to fetch ${requestParams.queryParams.type} file`);
      error.name = 'max_attempts_exceeded';
      return reject(error);
    } else {
      setTimeout(executeItrCreationPoll, interval, resolve, reject);
    }
  };

  return new Promise(executeItrCreationPoll);
};
