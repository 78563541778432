// General params
export const companyId = 'companyId';
export const userId = 'userId';
export const orderId = 'orderId';
export const locale = 'locale';
export const mode = 'mode';
export const model = 'model';
export const layout360 = 'layout360';
export const patientGuid = 'patientGuid';
export const rxGuid = 'rxGuid';
export const automationTestMode = 'automationTestMode';

//MIDC host only params - null in scanner host
export const timberUrl = 'timberUrl';
export const fromGenericApi = 'fromGenericApi';
export const serverEndpoint = 'serverEndpoint';

// Scanner host
export const localhost = 'localhost';
export const BaseUnitType = 'BaseUnitType';
export const ScannerPackageVersion = 'ScannerPackageVersion';

// PLUGIN PARAMS
//5D
export const isEVxEnabled = 'isEVxEnabled';
export const niri = 'niri';
export const isIOCEnabled = 'isIOCEnabled';

// Texture mapping
export const isTextureMappingDisabled = 'isTextureMappingDisabled';

// Timelapse
export const TimeLapseEnabled = 'TimeLapseEnabled';

// IOSim Plus
export const isIOSimPlusEnabled = 'isIOSimPlusEnabled';
export const simulationStatus = 'simulationStatus';
export const startSimulationTime = 'startSimulationTime';
export const expectedDuration = 'expectedDuration';

// Debug
export const debug = 'debug';
export const luminaDebug = 'luminaDebug';
export const standaloneMode = 'standaloneMode';

//Timber BI logs
export const SessionType = 'SessionType';
export const SessionId = 'SessionId';
