import { logToTimberBI, biMethods } from '../index';
import { utils } from '../../utils';
export default {
  app: {},
  plugins: {
    jaws_navigation: {
      jawsNavigationBITimberLog: ({ elementNamePrefix }, sdk) => {
        const eventName = `${elementNamePrefix} jaw`;

        logToTimberBI(
          biMethods.userActionBiLog({
            objectName: `${eventName} button`,
            action: 'click',
            objectType: 'button',
            locationParentObject: utils.getLocationParentObject(sdk),
            selectedValue: `${eventName} is clicked`,
            automationId: '',
          })
        );
      },
    },
    loupeDragEndEventBILog: ({ sdk }) => {
      logToTimberBI(
        biMethods.userActionBiLog({
          objectName: '5D Review Tool Plugin',
          action: 'click',
          objectType: 'button',
          locationParentObject: sdk && utils.getLocationParentObject(sdk),
          selectedValue: 'Review tool loupe ended',
          automationId: '',
        })
      );
    },
  },
  shared_logic: {},
  shared_ui: {},
};
