import { strings } from '@web-3d-tool/localization';

const { pluginsNames } = strings;

export const IOC = { id: 'ioc', name: 'ioc', loadable: false, rulesMatrixId: 'IOC' };
export const NIRI = { id: 'niri', name: 'niri', loadable: false, rulesMatrixId: 'NIRI' };
export const NiriAndIoc = { id: '_5D', name: 'niri', loadable: false };
export const OCC = { id: 'occ', name: pluginsNames.occ, loadable: false, rulesMatrixId: 'OCC' };
export const TIMELAPSE = { id: 'timelapse', name: pluginsNames.timelapse, loadable: false, rulesMatrixId: 'TIMELAPSE' };
export const COLOR = { id: 'color', name: pluginsNames.color, loadable: false, rulesMatrixId: 'COLOR' };
export const TOOGLE_MENU360 = { id: 'toogle-menu360', name: pluginsNames.toogleMenu360, loadable: false };
export const JAWS = { id: 'jaws', name: pluginsNames.jaws, loadable: false };
export const CAPTURE_TOOL = { id: 'captureTool', name: pluginsNames.captureTool, loadable: false };
export const DEBUG = { id: 'debug', name: pluginsNames.debug, loadable: false };
export const MODEL_COMPARE = {
  id: 'model-compare',
  name: pluginsNames.model_compare,
  loadable: false,
  rulesMatrixId: '_3DCOMPARE',
};
export const DITCH = { id: 'ditch', name: pluginsNames.ditch, loadable: false };
export const MARGIN_LINE = { id: 'margin-line', name: pluginsNames.marginLine, loadable: false };
export const TEXTURE_MAPPING = {
  id: 'texture-mapping',
  name: pluginsNames.textureMapping,
  loadable: false,
  rulesMatrixId: 'TEXTUREMAPPING',
};
export const STAGE_SPLITTER = { id: 'stage-splitter', name: pluginsNames.stageSplitter, loadable: false };
export const IOSIM_PLUS = {
  id: 'iosim-plus',
  name: pluginsNames.iosim_pro,
  loadable: true,
  rulesMatrixId: 'IOSIMPLUS',
};
export const DENTAL_XRAI = { id: 'dental-xrai', name: 'Dental Xrai', loadable: false, rulesMatrixId: 'DXR' };
export const GUIDED_TOUR = { id: 'guidedTour', name: 'Guided Tour', loadable: false };
export const VISIT_REPORT = {
  id: 'visit-report',
  name: 'Visit Report',
  loadable: false,
  rulesMatrixId: 'VISIT_REPORT',
};
