import { Vector3, Matrix4, Quaternion, MathUtils, Matrix3 } from 'three';

const addTranslation = (matrix, dx, dy, dz) => {
  matrix.elements[12] += dx / matrix.elements[15];
  matrix.elements[13] += dy / matrix.elements[15];
  matrix.elements[14] += dz / matrix.elements[15];
  return matrix;
};

const get_cam_to_abs_tx = (scan_to_cam_tx, local_to_world_tx) => {
  const cam_to_scan_tx = new Matrix4().copy(scan_to_cam_tx).invert();
  const cam_to_abs_tx = new Matrix4().multiplyMatrices(local_to_world_tx, cam_to_scan_tx);
  return cam_to_abs_tx;
};

const lin_mult = (vec3, matrix_tx) => {
  return new Vector3(...vec3.toArray()).applyMatrix3(new Matrix3().setFromMatrix4(matrix_tx));
};

const get_camera_pt = (cam_to_abs_tx) => {
  return new Vector3(0, 0, 0).applyMatrix4(cam_to_abs_tx);
};

const get_camera_dir = (cam_to_abs_tx) => {
  return lin_mult(new Vector3(0, 0, 1), cam_to_abs_tx);
};

const getCamToUiTx = (abs_to_ui_tx, cam_to_abs_tx) => {
  const cam_to_ui_tx = new Matrix4().multiplyMatrices(abs_to_ui_tx, cam_to_abs_tx);
  return cam_to_ui_tx;
};

// private
const noWeightsMult = (vector3, matrix) => {
  const result = new Vector3();
  result.x =
    matrix.elements[0] * vector3.x +
    matrix.elements[1] * vector3.y +
    matrix.elements[2] * vector3.z +
    matrix.elements[3];
  result.y =
    matrix.elements[4] * vector3.x +
    matrix.elements[5] * vector3.y +
    matrix.elements[6] * vector3.z +
    matrix.elements[7];
  result.z =
    matrix.elements[8] * vector3.x +
    matrix.elements[9] * vector3.y +
    matrix.elements[10] * vector3.z +
    matrix.elements[11];
  return result;
};

/*!
  Compute difference between two transformation matrices in terms of positional difference (mm) & rotational difference (in degrees).
  \return translation_diff_mm Translation of the reference point (>=0).
  \return rotation_diff_deg   Rotational difference [0:180 deg].
*/
const compute_tx_diff = (i_tx1, i_tx2, reference_point_in_scan_cs = new Vector3(9, 7, 8)) => {
  const i_tx2_invert = new Matrix4().copy(i_tx2).invert();
  const diffMatrix = new Matrix4().multiplyMatrices(i_tx1, i_tx2_invert);
  const quaternion = new Quaternion().setFromRotationMatrix(diffMatrix);

  // Get the angle relative to the identity quaternion
  const angleInRadians = quaternion.angleTo(new Quaternion());

  // convert from radians to easier-to-see degrees
  const rotation_diff_deg = MathUtils.radToDeg(angleInRadians);

  const tx1_position = noWeightsMult(reference_point_in_scan_cs, new Matrix4().copy(i_tx1).transpose());
  const tx2_position = noWeightsMult(reference_point_in_scan_cs, new Matrix4().copy(i_tx2).transpose());

  const translation_diff_mm2 = new Vector3().subVectors(tx1_position, tx2_position).length();
  return { trans_diff: translation_diff_mm2, angle_diff: rotation_diff_deg };
};

export { compute_tx_diff, getCamToUiTx, get_camera_dir, get_camera_pt, get_cam_to_abs_tx, lin_mult, addTranslation };
