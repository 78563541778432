import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { utils, timberWrapperService, currentActiveDebugFunctions } from '@web-3d-tool/shared-logic';
import { debounce, noop } from 'lodash';
import classNames from 'classnames';
import PinchZoomPan from './PinchZoomPan/PinchZoomPan';
import defaultStyles from './ImageFrame.module.css';
import styles360 from './ImageFrame360.module.css';
import { getCorrectImageSize, getMaxMovementFromPointInImageToFrameCenter } from './ImageFrame.logic';

const ImageFrame = (props) => {
  const { getIs360HubEnabled, getIsScanOriginLumina } = utils;
  const { debug_image_frame } = currentActiveDebugFunctions || {};

  const {
    width,
    height,
    src,
    brightness,
    contrast,
    rotation,
    shouldTransform,
    onChange,
    left,
    top,
    scale,
    id,
    dataBiType,
    selectedPointOnImage,
    isEnlargedFrame,
    type,
    luminaOriginalImageSize,
    elementOriginalImageSize,
    viewer360Align2DImages,
    numberOfItems,
    handleOnImageClick,
    imageZindex,
    EnlargeFrameButton,
    index,
    toggleEnlarge,
    modelType,
    isModelCompared,
  } = props;

  // params
  const luminaProps = {
    luminaOriginalImageSize: luminaOriginalImageSize,
    selectedPointOnLuminaImage: {
      x: 0,
      y: 0,
    },
  };
  const is360 = getIs360HubEnabled();
  const styles = is360 ? styles360 : defaultStyles;
  const isLuminaScan = getIsScanOriginLumina(modelType);
  const isLuminaRotation = !!(rotation === 90 || rotation === 270);
  const shouldHaveBlurredBackground = !viewer360Align2DImages && isLuminaScan;
  const runOnce = true;
  const containerStyle = classNames(styles.container, shouldHaveBlurredBackground ? styles.blackHiddenOverlay : '');

  // states
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [imageSize, setImageSize] = useState(null);
  const imageRef = useRef(null);
  const imageDimensionsRef = useRef({
    scale,
    top,
    left,
  });

  // functions
  const getNewValuesFromEvent = (args) => {
    const { scale: newScale, top: newTop, left: newLeft } = args;
    const { scale: prevScale, top: prevTop, left: prevLeft } = imageDimensionsRef.current;
    const hasNewValuesFromEvent = !(newScale === prevScale && newTop === prevTop && newLeft === prevLeft);

    imageDimensionsRef.current = {
      scale: newScale,
      top: newTop,
      left: newLeft,
    };

    if (hasNewValuesFromEvent) {
      if (newScale !== prevScale) {
        return 'zoom';
      } else {
        return 'pan';
      }
    }
    return '';
  };

  const debouncedHandlePinchZoomPanLog = useCallback(
    debounce((args) => {
      const eventType = getNewValuesFromEvent(args);

      if (eventType) {
        timberWrapperService.timber.shared_ui.imageFrame.imageFrameTimberLog({ eventType, eventSource: dataBiType });
      }
    }, 1000),
    []
  );

  const handlePinchZoomPanChange = (args) => {
    if (onChange) {
      onChange(args);
    }
    debouncedHandlePinchZoomPanLog(args);
  };

  // memos
  const inlineStyle = useMemo(() => {
    switch (true) {
      case is360 && isLuminaRotation:
        return { height, width, ...imageZindex };
      case is360 && !isLuminaRotation && !shouldHaveBlurredBackground && isModelCompared:
        return {
          width: 0,
          height,
          ...imageZindex,
        };
      default:
        return { width, height, ...imageZindex };
    }
  }, [height, imageZindex, is360, isLuminaRotation, isModelCompared, shouldHaveBlurredBackground, width]);

  const maxMovementFromPointInImageToFrameCenter = useMemo(
    () =>
      imageSize &&
      selectedPointOnImage &&
      getMaxMovementFromPointInImageToFrameCenter(
        imageSize,
        selectedPointOnImage,
        rotation,
        luminaProps,
        width,
        height
      ),
    [height, imageSize, luminaProps, rotation, selectedPointOnImage, width]
  );

  const imageCorrectSizes = useMemo(() => {
    const { width: correctWidth, height: correctHeight } = getCorrectImageSize(
      width,
      height,
      isEnlargedFrame,
      luminaOriginalImageSize,
      elementOriginalImageSize,
      viewer360Align2DImages,
      shouldHaveBlurredBackground,
      modelType
    );
    return { width: correctWidth, height: correctHeight };
  }, [
    width,
    height,
    isEnlargedFrame,
    luminaOriginalImageSize,
    elementOriginalImageSize,
    viewer360Align2DImages,
    shouldHaveBlurredBackground,
    modelType,
  ]);

  const getMaxSizeForBluredBaackground = useMemo(() => {
    return Math.max(width, height);
  }, [height, width]);

  const getTopLeft = useMemo(() => {
    const eventType = getNewValuesFromEvent({ top, left, scale });

    const isLuminaScanType = !!(!eventType && isLuminaScan && imageSize);

    if (isLuminaScanType && is360 && !viewer360Align2DImages && !shouldHaveBlurredBackground) {
      const getMaxMoveImage = maxMovementFromPointInImageToFrameCenter;
      return { left: getMaxMoveImage.moveLeft, top: getMaxMoveImage.moveTop };
    }

    if ((isLuminaScanType && !is360) || shouldHaveBlurredBackground) {
      const middleTopPoisition = height / 2 - (imageSize ? imageSize?.height / 2 : 0);
      return { left: 0, top: middleTopPoisition };
    }
    return { top, left };
  }, [
    height,
    imageSize,
    is360,
    isLuminaScan,
    left,
    maxMovementFromPointInImageToFrameCenter,
    scale,
    shouldHaveBlurredBackground,
    top,
    viewer360Align2DImages,
  ]);

  // effects
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setLoadingComplete(true);
    };
    img.src = src;
  }, [src, runOnce]);

  useEffect(() => {
    if (loadingComplete) {
      const { width, height } = imageCorrectSizes;
      setImageSize({ width, height });
    }
  }, [imageCorrectSizes, loadingComplete]);

  useEffect(() => {
    if (imageRef.current) imageRef.current.style.filter = `brightness(${brightness}%) contrast(${contrast}%)`;
  }, [brightness, contrast]);

  return (
    imageSize && (
      <div style={inlineStyle} className={containerStyle}>
        {shouldHaveBlurredBackground && (
          <div
            className={shouldHaveBlurredBackground ? styles.backgroundImage : ''}
            style={{ transform: `rotate(${rotation}deg)` }}
          >
            <div className={styles.blurredLayout}></div>
            <img src={src} alt="" height={getMaxSizeForBluredBaackground} width={getMaxSizeForBluredBaackground} />
          </div>
        )}
        <PinchZoomPan
          doubleTapBehavior="zoom"
          position="center"
          minScale={1}
          initialScale={1}
          maxScale={10}
          rotation={!isLuminaRotation || shouldHaveBlurredBackground ? rotation : rotation === 270 ? 180 : 0}
          shouldTransform={shouldTransform}
          frameWidth={shouldHaveBlurredBackground ? getMaxSizeForBluredBaackground : width}
          frameHeight={height}
          onChange={handlePinchZoomPanChange}
          shouldHaveBlurredBackground={shouldHaveBlurredBackground}
          left={getTopLeft.left}
          top={getTopLeft.top}
          scale={scale}
          inversePan={viewer360Align2DImages}
          debugElement={
            !!debug_image_frame && {
              loupeCoords: luminaProps.selectedPointOnLuminaImage,
              frameCenter: { x: width / 2 + (isLuminaRotation ? 0 : 30), y: height / 2 },
              type,
            }
          }
          isEnlargedFrame={isEnlargedFrame}
          isAlign2DImages={is360 && viewer360Align2DImages}
          isModelCompared={isModelCompared}
          numberOfItems={numberOfItems}
          isLuminaScan={isLuminaScan}
          type={type}
        >
          <img
            className={
              shouldHaveBlurredBackground || !viewer360Align2DImages ? styles.imageFrame : styles.image_frame_360
            }
            onClick={() => (viewer360Align2DImages ? handleOnImageClick(type) : {})}
            alt=""
            ref={imageRef}
            src={src}
            width={shouldHaveBlurredBackground ? getMaxSizeForBluredBaackground : imageSize.width}
            height={imageSize.height}
            data-test-id={id}
            id={id}
          />
          {viewer360Align2DImages && (
            <EnlargeFrameButton
              is360={is360}
              type={type}
              index={index}
              enlargedImage={isEnlargedFrame}
              handleEnlarge={toggleEnlarge}
              viewer360Align2DImages={viewer360Align2DImages}
            />
          )}
        </PinchZoomPan>
      </div>
    )
  );
};

ImageFrame.defaultProps = {
  width: 200,
  height: 200,
  src: '',
  brightness: 100,
  contrast: 100,
  rotation: 0,
  viewer360Align2DImages: false,
  imageZindex: 1,
  EnlargeFrameButton: {},
  toggleEnlarge: noop,
};

ImageFrame.propTypes = {
  /**
   * The image width
   */
  width: PropTypes.number,
  /**
   * The image width
   */
  height: PropTypes.number,
  /**
   * Image source
   */
  src: PropTypes.string.isRequired,
  /**
   * The number of percentage of brightness apply to the image
   */
  brightness: PropTypes.number,
  /**
   * The number of percentage of contrast apply to the image
   */
  contrast: PropTypes.number,
  /**
   * The rotation angle of the image
   */
  rotation: PropTypes.number,
  /**
   * Callback, fired when pinch, zoom, pan
   */
  onChange: PropTypes.func,
  /**
   * having 360 style
   */
  is360: PropTypes.bool,
};

export default ImageFrame;
