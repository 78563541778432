import { appSettingsManager } from '../app-settings-manager';
import { DENTAL_XRAI, IOC, IOSIM_PLUS, MODEL_COMPARE, NIRI, VISIT_REPORT } from '../constants/tools.constants';

const getAllAvailablePluginsFromRulesMatrix = () => {
  const { getToolsAvailability } = appSettingsManager.getAppSettings() || {};
  const pluginsObjects = [NIRI, IOC, MODEL_COMPARE, DENTAL_XRAI, IOSIM_PLUS, VISIT_REPORT];

  if (getToolsAvailability) {
    const filteredPlugins = pluginsObjects.filter((plugin) => {
      const rulesMatrixPlugin = getToolsAvailability.find(
        (availablePlugin) => availablePlugin.tool === plugin.rulesMatrixId
      );
      return rulesMatrixPlugin !== undefined && rulesMatrixPlugin.availability !== 'Hidden';
    });

    return filteredPlugins.map((plugin) => plugin.id);
  }

  return [];
};

const getIsPluginEnabled = (pluginId) => {
  const { getToolsAvailability } = appSettingsManager.getAppSettings() || {};

  if (getToolsAvailability) {
    const plugin = getToolsAvailability.find((availablePlugin) => availablePlugin.tool === pluginId);

    return plugin !== undefined && plugin.availability === 'Available';
  }

  return false;
};

export const pluginsAvailability = { getAllAvailablePluginsFromRulesMatrix, getIsPluginEnabled };
