import {
  logger,
  hostCommunicationManager,
  cacheManager,
  cacheKeys,
  utils,
  settingsManager,
  scannerVersionManager,
  Environments,
} from '@web-3d-tool/shared-logic';
import { lazyLoadPreset, composePreset } from '@web-3d-tool/plugins';
import * as configValues from '@web-3d-tool/shared-logic/src/constants/configurationValues.constants';
import { loadLanguage } from '@web-3d-tool/localization';
import { eupInit, scannerInit23A, scannerInit23C, initModeAOHS } from './shell.middleware.logic';
import * as AT from '../../actionTypes';
import {
  loadPreset,
  setAppPreset,
  loadAOHSPreset,
  setPluginsPresetId,
  setIsAOHSMode,
  setSLContext,
} from './shell.actions';
import { loadModel, loadNiri } from '../renderer/renderer.actions';
import createMiddleware from './../../../middlewareHelper';
import { loadPlugins, clearPlugins, loadExtendZonesPlugins } from '../plugins/plugins.actions';

//******************************
const feature = AT.SHELL;
//******************************

export const goThroughOverride = ({ action, dispatch, getState }) => {};
export const middleware = async ({ action, dispatch, getState }) => {
  const { payload, type } = action;
  if (!payload || !type) {
    return;
  }
  switch (type) {
    case AT.APP_LOADED:
      {
        const state = getState();

        if (state) {
          logger
            .info('Initializing')
            .to(['analytics', 'host'])
            .data({ module: 'app shell', state: JSON.stringify(state.shell) })
            .end();
        }
        hostCommunicationManager.initHostCommunicationManager();
        await loadLanguage();

        const loadPresetProps = {
          pluginsPresetId: 'default',
        };

        const host = utils.getEnv();
        const isEupHost = host === Environments.EUP;
        const isScannerHost = host === Environments.SCANNER;
        const isAOHS = host === Environments.AOHS;

        const { v23A, v23C } = scannerVersionManager.scannerAssetsVersions;
        const currentScannerVersion = scannerVersionManager.getScannerVersion();

        switch (true) {
          case isEupHost:
            await eupInit(dispatch, setSLContext);
            break;
          case isScannerHost && currentScannerVersion === v23A:
            await scannerInit23A();
            break;
          case isScannerHost && currentScannerVersion === v23C:
            await scannerInit23C(dispatch, setSLContext);
            break;
          case isAOHS:
            await initModeAOHS(dispatch, loadPreset, loadAOHSPreset, loadNiri, setSLContext);
            loadPresetProps.pluginsPresetId = 'aohs_lean_mode_clear';
            break;
          default:
            // no default
            break;
        }

        dispatch([loadModel(), loadPreset({ ...loadPresetProps }), setIsAOHSMode({ isAOHS })]);
      }
      break;

    case AT.LOAD_PRESET:
      {
        const presetId =
          getState().shell.presetId ||
          (utils.getIs360HubEnabled() ? '360' : settingsManager.getConfigValue(configValues.mode));
        const { forceReloadPreset, pluginsPresetId, initiatingPlugin } = payload;

        try {
          let preset;
          if (forceReloadPreset) {
            preset = await lazyLoadPreset(presetId);
          } else {
            preset = cacheManager.get(cacheKeys.PRESET) || (await lazyLoadPreset(presetId));
          }

          cacheManager.set(cacheKeys.PRESET, preset);
          const appPreset = await composePreset({ preset, pluginsPresetId });
          dispatch([
            clearPlugins({ initiatingPlugin }),
            setAppPreset({ appPreset }),
            loadPlugins({ initiatingPlugin }),
            setPluginsPresetId({ pluginsPresetId }),
          ]);
        } catch (err) {
          logger
            .error('LOAD_PRESET')
            .to(['analytics', 'host'])
            .data({ module: 'app shell', err: err })
            .end();
        }
      }
      break;

    case AT.ADD_PLUGINS:
      {
        const { presets } = payload;
        dispatch(loadExtendZonesPlugins({ presets }));
      }
      break;

    case AT.LOAD_AOHS_PRESET:
      {
        const { presetProps } = payload;
        dispatch([loadPreset({ ...presetProps })]);
      }
      break;

    default:
      //do nothing
      break;
  }
};

export default createMiddleware({
  feature,
  goThroughOverride,
})(middleware);
