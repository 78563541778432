// core - API
export const API_REQUEST = 'API_REQUEST';
// const apiAction = action => ({
//   SOURCE: `${action}`,
//   API_REQUEST: `${action}.${API_REQUEST}`,
//   SUCCESS: `${action}.SUCCESS`,
//   ERROR: `${action}.ERROR`,
// });

// core - loaders
export const LOADERS = '[LOADERS]';
export const SET_LOADER = 'SET_LOADER';

// shell
export const SHELL = '[SHELL]';
export const UPDATE_SHELL_STATE = 'UPDATE_SHELL_STATE';
export const APP_LOADED = 'APP_LOADED';
export const LOAD_PRESET = 'LOAD_PRESET';
export const PRESET_LOADED = 'PRESET_LOADED';
export const SET_APP_PRESET = 'SET_APP_PRESET';
export const SET_PLUGINS_PRESET_ID = 'SET_PLUGINS_PRESET_ID';
export const SET_PRESET_ID = 'SET_PRESET_ID';
export const ADD_PLUGINS = 'ADD_PLUGINS';
export const LOAD_AOHS_PRESET = 'LOAD_AOHS_PRESET';
export const SET_IS_AOHS_MODE = 'SET_IS_AOHS_MODE';
export const SET_CONTEXT = 'SET_CONTEXT';

// renderer
export const RENDERER = '[RENDERER]';
export const LOAD_MODEL = 'LOAD_MODEL';
export const LOAD_NIRI = 'LOAD_NIRI';
export const LOAD_COMPARE_MODEL = 'LOAD_COMPARE_MODEL';
export const UNLOAD_COMPARE_MODEL = 'UNLOAD_COMPARE_MODEL';
export const MODEL_LOADED = 'MODEL_LOADED';
export const GET_MODEL = 'GET_MODEL';
export const GET_NIRI_DATA = 'GET_NIRI_DATA';
export const SET_NIRI_DATA = 'SET_NIRI_DATA';
export const GET_MATERIALS = 'GET_MATERIALS';
export const SET_MODEL_ID = 'SET_MODEL_ID';
export const SET_COMPARE_MODEL_ID = 'SET_COMPARE_MODEL_ID';
export const SET_METADATA = 'SET_METADATA';
export const SET_COMPARE_METADATA = 'SET_COMPARE_METADATA';
export const MODEL_COMPARE_ACTIVE = 'MODEL_COMPARE_ACTIVE';
export const ACTIVEATE_MODEL_COMPARE = 'ACTIVEATE_MODEL_COMPARE';
export const LOADING_MODEL_ERROR = 'LOADING_MODEL_ERROR';
export const CHANGE_BASE_MATERIAL = 'CHANGE_BASE_MATERIAL';
export const CHANGE_MODEL_OBJECTS_VISIBILITY = 'CHANGE_MODEL_OBJECTS_VISIBILITY';
export const CHANGE_MODEL_OBJECTS_MULTIBITE = 'CHANGE_MODEL_OBJECTS_MULTIBITE';
export const GET_MODEL_OBJECTS_VISIBILITY = 'GET_MODEL_OBJECTS_VISIBILITY';
export const IMPERATIVE_THREE_OBJECTS_READY = 'IMPERATIVE_THREE_OBJECTS_READY';
export const CAMERA_IS_MOVING = 'CAMERA_IS_MOVING';
export const CAMERA_STOPPED_MOVING = 'CAMERA_STOPPED_MOVING';
export const SET_IS_THREEJS_OBJECTS_READY = 'SET_IS_THREEJS_OBJECTS_READY';
export const TWO_FINGERS_TAP = 'TWO_FINGERS_TAP';
export const TWO_FINGERS_DOUBLE_TAP = 'TWO_FINGERS_DOUBLE_TAP';
export const SET_READONLY_STAGE = 'SET_READONLY_STAGE';
export const SET_RESET_CAMERA_ROTATION_ON_UPDATE = 'SET_RESET_CAMERA_ROTATION_ON_UPDATE';
export const SET_STAGE = 'SET_STAGE';
export const SET_MODEL_IS_LOADING = 'SET_MODEL_IS_LOADING';
export const MODEL_COMPARE_TOGGLE_SYNC = 'MODEL_COMPARE_TOGGLE_SYNC';
export const TOGGLE_SYNC = 'TOGGLE_SYNC';

// plugins
export const PLUGINS = '[PLUGINS]';
export const LOAD_PLUGINS = 'LOAD_PLUGINS';
export const EXTEND_ZONES_PLUGINS = 'EXTEND_ZONES_PLUGINS';
export const CLEAR_PLUGINS = 'CLEAR_PLUGINS';
export const INIT_PLUGINS = 'INIT_PLUGINS';
export const SUBSCRIBE_TO_PLUGIN_EVENT = 'SUBSCRIBE_TO_PLUGIN_EVENT';
export const UNSUBSCRIBE_FROM_PLUGIN_EVENT = 'UNSUBSCRIBE_FROM_PLUGIN_EVENT';
export const ADD_PLUGIN_VIEW = 'ADD_PLUGIN_VIEW';
export const REMOVE_PLUGIN_VIEW = 'REMOVE_PLUGIN_VIEW';
export const CLEAR_AND_DESTROY_PLUGINS = 'CLEAR_AND_DESTROY_PLUGINS';
export const SET_PLUGIN_VIEWS = 'SET_PLUGIN_VIEWS';
export const ADD_TEXTURE = 'ADD_TEXTURE';
export const ADD_MULTIPLE_TEXTURES = 'ADD_MULTIPLE_TEXTURES';
export const ADD_COMPARE_TEXTURE = 'ADD_COMPARE_TEXTURE';
export const ADD_MULTIPLE_COMPARE_TEXTURES = 'ADD_MULTIPLE_COMPARE_TEXTURES';
export const REMOVE_TEXTURE = 'REMOVE_TEXTURE';
export const REMOVE_MULTIPLE_TEXTURES = 'REMOVE_MULTIPLE_TEXTURES';
export const REMOVE_COMPARE_TEXTURE = 'REMOVE_COMPARE_TEXTURE';
export const REMOVE_MULTIPLE_COMPARE_TEXTURES = 'REMOVE_MULTIPLE_COMPARE_TEXTURES';
export const SET_TEXTURES = 'SET_TEXTURES';
export const SET_COMPARE_TEXTURES = 'SET_COMPARE_TEXTURES';
export const SPLIT_RENDERING_STAGE = 'SPLIT_RENDERING_STAGE';
export const SET_RENDERING_STAGE = 'SET_RENDERING_STAGE';
export const SET_RENDERING_READONLY_STAGE = 'SET_RENDERING_READONLY_STAGE';
export const ACTIVATE_TOOL = 'ACTIVATE_TOOL';
export const DEACTIVATE_TOOL = 'DEACTIVATE_TOOL';
export const SUBSCRIBE_TO_TOOL_EVENT = 'SUBSCRIBE_TO_TOOL_EVENT';
export const UNSUBSCRIBE_FROM_TOOL_EVENT = 'UN_SUBSCRIBE_FROM_TOOL_EVENT';
export const EVALUATE_PRESET_DUE_TO_PLUGIN_ACTIVATION = 'EVALUATE_PRESET_DUE_TO_PLUGIN_ACTIVATION';
export const SET_PRESET = 'SET_PRESET';
export const SET_PLUGINS_ZONES = 'SET_PLUGINS_ZONES';
export const SET_PLUGIN_STATE = 'SET_PLUGIN_STATE';
export const SET_PLUGIN_LOADING_STATE = 'SET_PLUGIN_LOADING_STATE';
export const SET_PLUGIN_PARAMETERS = 'SET_PLUGIN_PARAMETERS';
export const RESET_PLUGIN_PARAMETERS = 'SET_PLUGIN_PARAMETERS';
export const SET_PLUGIN_VISIBLE_STATE = 'SET_PLUGIN_VISIBLE_STATE';
export const SET_PLUGIN_STATUS_MESSAGE = 'SET_PLUGIN_STATUS_MESSAGE';
export const CHANGE_PLUGIN_ZONES = 'CHANGE_PLUGIN_ZONES';
export const ACTIVATE_TOGGLE_MODE = 'ACTIVATE_TOGGLE_MODE';
export const DEACTIVATE_TOGGLE_MODE = 'DEACTIVATE_TOGGLE_MODE';
export const RESET_STAGE = 'RESET_STAGE';
export const ADD_DYNAMIC_PLUGINS = 'ADD_DYNAMIC_PLUGINS';

// tools
export const TOOLS = '[TOOLS]';
export const SET_TOOL_ACTIVE = 'SET_TOOL_ACTIVE';
export const SET_TOOL_CONFIG = 'SET_TOOL_CONFIG';
export const CHANGE_TOOL_CONFIG = 'CHANGE_TOOL_CONFIG';
