export const ViewerSoftwareOptions = {
  ShouldEnableEvx: 2087,
  ShouldEnableIntraoralCameraTool: 2092,
  ShouldEnableTextureMappingInViewer: 2230,
  Viewer360HistoricalComparisonManual2DComapre: 2421,
  SideBySideCompare: 2403,
  DentalXr: 2406,
  NGNIRI: 2430,
  NGIOC: 2431,
};

export const SoftwareOptionsForCompany = {
  InvisalignOutcomeSimulator: 2249,
  ShouldDisableTextureMappingForCompany: 2231,
  DentalXrGA: 2443,
  VisitReportLmr: 2456,
};
