import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../../Button';
import stylesDefault from './Bite.module.css';
import styles360 from './Bite360.module.css';

const propTypes = {
  /**
   * If `true`, bite is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Callback, fired when clicking on bite
   */
  onClick: PropTypes.func,
  /**
   * Is MultiBite active
   */
  multiBiteActive: PropTypes.bool,
  /**
   * Is MultiBite enabled
   */
  isMultiBite: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
  multiBiteActive: false,
  isMultiBite: false,
};

function Bite(props) {
  const { className: classNameProp, disabled, onClick, isMultiBite, multiBiteActive, currentBite, is360 } = props;
  const styles = is360 ? styles360 : stylesDefault;
  const className = classNames(classNameProp, {
    [styles.isDefault]: !disabled && !isMultiBite,
    [styles.isDisabled]: disabled,
    [styles.isOneSelected]: isMultiBite && !multiBiteActive,
    ...(isMultiBite && multiBiteActive
      ? {
          [styles.isTwoSelected]: currentBite === 2,
          [styles.isThreeSelected]: currentBite === 3,
          [styles.isFourSelected]: currentBite === 4,
          [styles.isFiveSelected]: currentBite === 5,
          [styles.isSixSelected]: currentBite === 6,
        }
      : {}),
  });

  return (
    <Button className={className} disabled={disabled} onClick={onClick} data-test-id="bite" id="bite">
      <div className={styles.after} />
    </Button>
  );
}

Bite.propTypes = propTypes;
Bite.defaultProps = defaultProps;

export default Bite;
