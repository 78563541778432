export default Object.freeze({
  max_distance_to_selected_pt_mm: 9.0,
  max_dist_from_cam_to_img_cen_on_surf: 14.0,
  angie_max_distance_between_pr_pt_and_cam_mm: 30.0,
  pix_dist_coef: 1 / 300,
  angie_height_coef: 1 / 30,
  rail_max_return_time: 3000, //ms
  surface_projection_segment_size: 1.0,
  projection_distance_compliance_coefficient: 0.5,
  angle_compliance_coefficient: 0.35,
  preferable_angle_difference_between_view_and_image: 40.0,

  max_angle_difference_between_view_and_image: 30.0,
  max_angle_difference_between_view_and_image_rails: 90.0,

  max_rail_candidates: 50,
  rail_score_threshold: 0.0,
  max_position_difference_between_sector_center_and_image_projection: 10.0, // image selection radius top end.
  min_image_capture_height: 7.0,
  max_image_capture_height: 35.0,
  preferable_image_capture_height: 8.0,
  preferable_position_difference_between_sector_center_and_image_projection: 6.0, // image selection radius preferable range.
  position_compliance_coefficient: 0.5,
  image_capture_height_compliance_coefficient: 0.15,
  minimal_distance_approximation: 0.5,
  replacement_threshold: 1,
  camera_count: 6,
  epsilon: 0,
  min_distance_between_camera_and_segment_center_projection: 7,
  max_distance_between_camera_and_segment_center_projection: 35,
  max_distance_between_image_and_segment_center_projections: 14,

  angle_difference_penalty_coefficient: 1,

  caries_detection_score_threshold: 0.85,
  caries_detection_score_amp: 1,
  caries_detection_penalty_coefficient: 1000000000,

  max_image_rotation_threshold: 45, // deg

  degreValues: {
    e_0_deg: 0,
    e_90_deg: 90,
    e_180_deg: 180,
    e_270_deg: 270,
  },

  RATIO_PIXEL_TO_ANGLE: 600,
});
