import React from 'react';

import { MeshWithTextureMapping } from './MeshWithTextureMapping';
import { MeshWithOCCTexture } from './MeshWithOCCTexture';

const texturedMesh = {};
export const MeshWithTexture = (props) => {
  const { geometry, modelName, texture, shouldRenderTextureMapping, isLuminaScan } = props;
  const textureName = texture.name;

  if (!texturedMesh[textureName] || texturedMesh[textureName].map.uuid !== texture.map.uuid) {
    texturedMesh[textureName] = texture;
    texturedMesh[textureName].map = texture.map;
  }

  const { map, name } = texturedMesh[textureName];

  return (
    <>
      {shouldRenderTextureMapping && (
        <MeshWithTextureMapping
          geometry={geometry}
          map={map}
          textureName={name}
          modelName={modelName}
          isLuminaScan={isLuminaScan}
        />
      )}
      <MeshWithOCCTexture geometry={geometry} map={map} textureName={name} modelName={modelName} />
    </>
  );
};
