import logger from '../../logger';
import { getITRModelWithPolling, fetchNiriFileWithPolling } from '../../api-requests/model-fetcher/itr-fetcher.service';
import * as configuration from '../../constants/configurationValues.constants';
import { settingsManager } from '../../settings-manager';
import { Environments } from '../../constants/environment.constants';
import { logToTimberBI, biMethods } from '../../timberLogger';

const getNiriFilePath = () => {
  return settingsManager.getConfigValue(configuration.niri);
};

const getModelUrl = () => {
  return settingsManager.getConfigValue(configuration.model);
};

const getNiriFile = async (progressCB, niriPath) => {
  try {
    const niriFilePath = niriPath || getNiriFilePath();

    const requestParams = {
      url: niriFilePath,
    };

    return await fetchNiriFileWithPolling({
      requestParams,
      environment: Environments.SCANNER,
      progressCB,
    });
  } catch (error) {
    logger
      .error('No NIRI file path')
      .data({ module: 'scannerRequests' })
      .end();

    logToTimberBI(
      biMethods.errorReportBiLog({
        object: 'scannerRequests',
        code: 'No NIRI file path',
        description: error.message,
        severity: 'Fatal - No NIRI file path',
      })
    );
  }
};

const getModel = async () => {
  const modelUrl = getModelUrl();

  const requestParams = {
    url: modelUrl,
  };

  return await getITRModelWithPolling(Environments.SCANNER, requestParams);
};

const getItrModel = async () => {
  return await getModel();
};

export { getNiriFile, getItrModel, getModelUrl, getNiriFilePath };
