import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../../Button';
import stylesDefault from './UpperJaw.module.css';
import styles360 from './UpperJaw360.module.css';

const propTypes = {
  /**
   * If `true`, will render as disabled
   */
  disabled: PropTypes.bool,
  /**
   * Callback, fired on clicking on jaw
   */
  onClick: PropTypes.func,
  /**
   * If `true`, will render as selected
   */
  selected: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
  onClick: () => {},
  selected: false,
};

function UpperJaw(props) {
  const { className: classNameProp, disabled, onClick, selected, is360 } = props;
  const styles = is360 ? styles360 : stylesDefault;
  const className = classNames(classNameProp, {
    [styles.isDefault]: !(disabled || selected),
    [styles.isDisabled]: disabled,
    [styles.isSelected]: selected,
  });

  return (
    <Button className={className} disabled={disabled} onClick={onClick} data-test-id="upper-jaw" id="upper-jaw">
      <div className={styles.after} />
    </Button>
  );
}

UpperJaw.propTypes = propTypes;
UpperJaw.defaultProps = defaultProps;

export default UpperJaw;
