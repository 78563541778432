/* eslint-disable no-unused-vars */
import { ViewerSoftwareOptions, SoftwareOptionsForCompany } from '../constants/enums.constants';
import { TIMELAPSE, MODEL_COMPARE, IOSIM_PLUS, DENTAL_XRAI, NIRI, VISIT_REPORT } from '../constants/tools.constants';
import { utils } from '../utils';
import { scannerVersionManager } from '../scanner-version-manager/scanner-version-manager';
import {
  getAvailbilityForPluginOrFeature,
  getIsIntraOralCameraAndNiriEnabled,
  getIsIntraOralCameraEnabled,
  getIsNGNiriEnabledForLuminaPro,
  getIsNGNiriHidden,
} from './feature-availability-utils';

const getAllAvaliableFeaturesIds = () => {
  const avaliableFeaturesArray = [];
  const isModelCompareEnabled = isSideBySideCompareEnabled();
  const isIOSimPlusEnabled = getIsIOSimPlusEnabled();
  const isTimeLapseEnabled = getIsTimeLapseEnabled();
  const isDentalXraiEnabled = getIsDentalXraiEnabled();
  const isNiriEnabled = !getIsNGNiriHidden();
  const isVisitReport = getIsVisitReportEnabled() && getIsVisitReportEnabledGA();
  isDentalXraiEnabled && avaliableFeaturesArray.push(DENTAL_XRAI.id);
  isModelCompareEnabled && avaliableFeaturesArray.push(MODEL_COMPARE.id);
  isIOSimPlusEnabled && avaliableFeaturesArray.push(IOSIM_PLUS.id);
  isTimeLapseEnabled && avaliableFeaturesArray.push(TIMELAPSE.id);
  isNiriEnabled && avaliableFeaturesArray.push(NIRI.id);
  isVisitReport && avaliableFeaturesArray.push(VISIT_REPORT.id);
  return avaliableFeaturesArray;
};

const isSideBySideCompareEnabled = () => {
  const resolveFunction = (isFeatureFlagEnabled, isCompanySwoEnabled, isScannerSwoEnabled) => {
    return isScannerSwoEnabled;
  };

  return getAvailbilityForPluginOrFeature({
    featureFlagNames: ['SideBySideCompare'],
    scannerSwo: ViewerSoftwareOptions.SideBySideCompare,
    resolveFunction,
  });
};

const getIsTimeLapseEnabled = () => {
  const isEup = utils.isEupHostEnv();
  const isAOHS = utils.isAOHSEnv();
  if (isEup || isAOHS) return false;

  const resolveFunction = (isFeatureFlagEnabled, isCompanySwoEnabled, isScannerSwoEnabled) => {
    return true;
  };

  return getAvailbilityForPluginOrFeature({
    featureFlagNames: [],
    companySwo: null,
    resolveFunction,
  });
};

const getIsIOSimPlusEnabled = () => {
  // TODO implement
  const resolveFunction = (isFeatureFlagEnabled, isCompanySwoEnabled, isScannerSwoEnabled) => {
    const isIOSimPlusEnabled = false;
    const iosimProps = {
      expectedDuration: null,
      simulationStatus: null,
      startSimulationTime: null,
    };
    return true;
  };

  return getAvailbilityForPluginOrFeature({
    featureFlagNames: [],
    scannerSwo: null,
    resolveFunction,
  });
};

const getIsDentalXraiEnabled = () => {
  const resolveFunction = (isFeatureFlagEnabled, isCompanySwoEnabled, isScannerSwoEnabled) => {
    return isFeatureFlagEnabled || isCompanySwoEnabled || isScannerSwoEnabled;
  };

  return getAvailbilityForPluginOrFeature({
    featureFlagNames: ['DentalXRGAEnabled'],
    companySwo: SoftwareOptionsForCompany.DentalXrGA,
    scannerSwo: ViewerSoftwareOptions.DentalXr,
    resolveFunction,
  });
};

const getViewer360GuidedTour = () => {
  const resolveFunction = (isFeatureFlagEnabled, isCompanySwoEnabled, isScannerSwoEnabled) => {
    return isFeatureFlagEnabled;
  };

  return getAvailbilityForPluginOrFeature({
    featureFlagNames: ['Viewer360GuidedTour'],
    scannerSwo: null,
    resolveFunction,
  });
};

// Align orientation SWO and FF were changed to be aligned to the behavior of 2D compare feature and thus it uses it FF and SWO (TR-15710)
const getViewer360Align2DImages = () => {
  const resolveFunction = (isFeatureFlagEnabled, isCompanySwoEnabled, isScannerSwoEnabled) => {
    const is360 = utils.getIs360HubEnabled();
    return is360 && isFeatureFlagEnabled && isScannerSwoEnabled;
  };

  return getAvailbilityForPluginOrFeature({
    featureFlagNames: ['Viewer360HistoricalComparison2DComapre'],
    scannerSwo: ViewerSoftwareOptions.Viewer360HistoricalComparisonManual2DComapre,
    resolveFunction,
  });
};

const getIsWasmEnabled = () => {
  const resolveFunction = (isFeatureFlagEnabled, isCompanySwoEnabled, isScannerSwoEnabled) => {
    return isFeatureFlagEnabled;
  };

  return getAvailbilityForPluginOrFeature({
    featureFlagNames: ['ViewerWasmEnabled'],
    scannerSwo: null,
    resolveFunction,
  });
};

const getNGNiriEnabled = () => {
  const resolveFunction = (isFeatureFlagEnabled, companySwo, scannerSwo) => {
    const isScanner = utils.isScannerHostEnv();
    if (isScanner) {
      const scannerCurrentVersion = utils.getScannerPackageVersion();
      const isScannerOfVersion = scannerVersionManager.checkScannerPackageVersions(scannerCurrentVersion, [
        { major: 4 },
      ]);
      return (isScannerOfVersion && scannerSwo) || getIsIntraOralCameraAndNiriEnabled();
    } else {
      return scannerSwo;
    }
  };

  return getAvailbilityForPluginOrFeature({
    featureFlagNames: [],
    scannerSwo: ViewerSoftwareOptions.NGNIRI,
    resolveFunction,
  });
};

const getNGIocEnabled = () => {
  const resolveFunction = (isFeatureFlagEnabled, companySwo, scannerSwo) => {
    const isScanner = utils.isScannerHostEnv();
    if (isScanner) {
      const scannerCurrentVersion = utils.getScannerPackageVersion();
      const isScannerOfVersion = scannerVersionManager.checkScannerPackageVersions(scannerCurrentVersion, [
        { major: 3 },
        { major: 4 },
      ]);
      return (
        (isScannerOfVersion && scannerSwo) ||
        getIsIntraOralCameraEnabled() ||
        getIsIntraOralCameraAndNiriEnabled() ||
        getIsNGNiriEnabledForLuminaPro()
      );
    } else {
      return true;
    }
  };

  return getAvailbilityForPluginOrFeature({
    featureFlagNames: [],
    scannerSwo: ViewerSoftwareOptions.NGIOC,
    resolveFunction,
  });
};

const getIsReviewToolInCompareModeEnabled = () => {
  const resolveFunction = (isFeatureFlagEnabled, isCompanySwoEnabled, isScannerSwoEnabled) => {
    return isCompanySwoEnabled || isScannerSwoEnabled;
  };

  return getAvailbilityForPluginOrFeature({
    featureFlagNames: [],
    companySwo: null,
    scannerSwo: ViewerSoftwareOptions.Viewer360HistoricalComparisonManual2DComapre,
    resolveFunction,
  });
};

const getIsRulesMatrixEnabled = () => {
  const isAOHS = utils.isAOHSEnv();
  const resolveFunction = (isFeatureFlagEnabled, companySwo, scannerSwo) => {
    const isScanner = utils.isScannerHostEnv();
    return !isScanner && isFeatureFlagEnabled;
  };

  return (
    isAOHS ||
    getAvailbilityForPluginOrFeature({
      featureFlagNames: ['Web3dToolUseRulesMatrix'],
      scannerSwo: null,
      resolveFunction,
    })
  );
};

const getIsAssetManagerEnabled = () => {
  const resolveFunction = (isFeatureFlagEnabled, companySwo, scannerSwo) => {
    return isFeatureFlagEnabled;
  };

  return getAvailbilityForPluginOrFeature({
    featureFlagNames: ['UseAssetManagerInWeb3DTool'],
    scannerSwo: null,
    resolveFunction,
  });
};

const getIsAOHSGalleryViewEnabled = () => {
  const resolveFunction = (isFeatureFlagEnabled, companySwo, scannerSwo) => {
    return isFeatureFlagEnabled;
  };

  return getAvailbilityForPluginOrFeature({
    featureFlagNames: ['AlignOralHealthSuiteGalleryView'],
    scannerSwo: null,
    resolveFunction,
  });
};

const getIsEnableNGNiriImageSelectionAlgo = () => {
  const resolveFunction = (isFeatureFlagEnabled, companySwo, scannerSwo) => {
    return isFeatureFlagEnabled;
  };

  return getAvailbilityForPluginOrFeature({
    featureFlagNames: ['EnableNGNiriImageSelectionAlgo'],
    scannerSwo: null,
    resolveFunction,
  });
};

const getIsPendoToggleAvailiable = () => {
  const resolveFunction = (isFeatureFlagEnabled, companySwo, scannerSwo) => {
    return isFeatureFlagEnabled;
  };

  return getAvailbilityForPluginOrFeature({
    featureFlagNames: ['PendoToggle'],
    scannerSwo: null,
    resolveFunction,
  });
};

const getIsPendoInitByDefaultForAllClients = () => {
  const resolveFunction = (isFeatureFlagEnabled, companySwo, scannerSwo) => {
    return isFeatureFlagEnabled;
  };

  return getAvailbilityForPluginOrFeature({
    featureFlagNames: ['PendoInitOnAppStart'],
    scannerSwo: null,
    resolveFunction,
  });
};

const getIsVisitReportEnabledGA = () => {
  const resolveFunction = (isFeatureFlagEnabled, isCompanySwoEnabled, isScannerSwoEnabled) => {
    return isFeatureFlagEnabled || isCompanySwoEnabled;
  };
  return getAvailbilityForPluginOrFeature({
    featureFlagNames: ['ShareScanReportByQR_GA'],
    companySwo: SoftwareOptionsForCompany.VisitReportLmr,
    scannerSwo: null,
    resolveFunction,
  });
};

const getIsVisitReportEnabled = () => {
  const resolveFunction = (isFeatureFlagEnabled, isCompanySwoEnabled, isScannerSwoEnabled) => {
    return isFeatureFlagEnabled;
  };

  return getAvailbilityForPluginOrFeature({
    featureFlagNames: ['ScanReportInsideAOHS'],
    companySwo: null,
    scannerSwo: null,
    resolveFunction,
  });
};

export const featureAvaliability = {
  isSideBySideCompareEnabled,
  getIsTimeLapseEnabled,
  getIsDentalXraiEnabled,
  getAllAvaliableFeaturesIds,
  getViewer360GuidedTour,
  getViewer360Align2DImages,
  getIsWasmEnabled,
  getNGNiriEnabled,
  getNGIocEnabled,
  getIsReviewToolInCompareModeEnabled,
  getIsRulesMatrixEnabled,
  getIsAssetManagerEnabled,
  getIsIOSimPlusEnabled,
  getIsAOHSGalleryViewEnabled,
  getIsEnableNGNiriImageSelectionAlgo,
  getIsPendoToggleAvailiable: getIsPendoToggleAvailiable,
  getIsPendoInitByDefaultForAllClients: getIsPendoInitByDefaultForAllClients,
  getIsVisitReportEnabled,
  getIsVisitReportEnabledGA,
};
