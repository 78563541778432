import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import LoadingProgress from '../LoadingProgress/LoadingProgress';
import stylesNo360 from './ImageFramesContainer.module.css';
import styles360 from './ImageFramesContainer360.module.css';

const EmptyPanel = (props) => {
  const { src, type, is360, isReady, frameSize, drawerWidth, style, setEmptyPanelRef, index } = props;

  const placeholderIconRef = useRef(null);

  const styles = is360 ? styles360 : stylesNo360;

  const { width, height } = frameSize;

  useEffect(() => {
    index === 0 && setEmptyPanelRef(placeholderIconRef);
  }, [setEmptyPanelRef, index]);

  return (
    <div
      data-test-id={`${type}-image-container`}
      className={classNames(styles.placeholder)}
      style={{ height: height, width: width + drawerWidth, ...style }}
      ref={placeholderIconRef}
    >
      {!!(width && height) && height > 0 && (
        <>
          {is360 && <>{!isReady && <LoadingProgress classNameContainerProp={styles.spinnerContainer} />}</>}
          <img src={src} alt={`${type}-icon`} />
        </>
      )}
    </div>
  );
};

EmptyPanel.propTypes = {
  /**
   * image icon source
   */
  src: PropTypes.string,
  /**
   * image type niri / color
   */
  type: PropTypes.string,
  /**
   * viewer mode
   */
  is360: PropTypes.bool,
  /**
   * images loading progress status
   */
  isReady: PropTypes.bool,
  /**
   * frame width / height
   */
  frameSize: PropTypes.object,
};

export default EmptyPanel;
