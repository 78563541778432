import { IteroEventBus } from '@itero/itero-client-communication';
import { eventBus, globalEventsKeys } from '../event-bus';
import { iteroEventBusEvents } from './iteroEventBus.constants';
import ClientCommunicationEventsHandlers from './classes/clientCommunicationEventsHandlers';
import refreshRequest from '../api-service/refreshService';
import logger from '../logger';

const appName = 'web-3d-tool';

const handleCommunicationEvents = (event, eventHandlers) => {
  const { eventName, publisher } = (event && event.eventHeader) || {};

  switch (eventName) {
    case iteroEventBusEvents.platformReady:
      eventHandlers.platformReadyHandler(event);
      break;
    case iteroEventBusEvents.appLoaded:
      eventHandlers.appLoadedHandler(event);
      break;
    case iteroEventBusEvents.initContext:
      eventHandlers.initContextHandler(event);
      break;
    case iteroEventBusEvents.languageChanged:
      eventHandlers.languageChangedHandler(event);
      break;
    case iteroEventBusEvents.tokenExpired:
      eventHandlers.tokenExpiredHandler(event);
      break;
    case iteroEventBusEvents.sessionUpdated:
      eventHandlers.sessionUpdatedHandler(event);
      break;
    case iteroEventBusEvents.handleError:
      eventHandlers.handleErrorHandler(event);
      break;
    default:
      // no default event handler
      logger
        .warn('Not supported event from client communication')
        .to(['analytics', 'host'])
        .data({ eventName })
        .end();
      break;
  }
};

const init = () => {
  const iteroEventBus = new IteroEventBus();

  window.iteroEventBus = iteroEventBus;

  const eventHandlers = new ClientCommunicationEventsHandlers(iteroEventBus, appName, refreshRequest);

  eventBus.subscribeToEvent(globalEventsKeys.RAISE_CLIENT_COMMUNICATION_EVENT, (event) => {
    handleCommunicationEvents(event, eventHandlers);
  });
  iteroEventBus.on('platform-channel', appName, (event) => handleCommunicationEvents(event, eventHandlers));
};

export default {
  init,
  handleCommunicationEvents,
};
