const web3DViewerBffMap = {
  getModelByType: {
    method: 'GET',
    path: 'api/v1/DownloadFile/GetModelByType',
  },
  IsItrFileExists: {
    method: 'GET',
    path: 'api/v1/DownloadFile/IsItrFileExists',
  },
  getPatientOrdersForComparison: {
    method: 'GET',
    path: 'api/v1/PatientOrders/GetPatientOrdersForComparison',
  },
  getAllFlagsState: {
    method: 'GET',
    path: 'api/v1/Settings/GetAllFlagsState',
  },
  areScannerSoftwareOptionsAvailable: {
    method: 'GET',
    path: 'api/v1/Settings/areScannerSoftwareOptionsAvailable',
  },
  getCompanySoftwareOptions: {
    method: 'GET',
    path: 'api/v1/Settings/getCompanySoftwareOptions',
  },
  getToolsAvailability: {
    method: 'GET',
    path: 'api/v1/RulesMatrix/getToolsAvailability',
  },
};

const scannerMATApiMap = {
  getModelByType: {
    method: 'GET',
    path: 'webviewer/getModelByType',
  },
  getFeaturesToggleSettings: {
    method: 'GET',
    path: 'webviewer/getFeaturesToggleSettings',
  },
  getCompanySoftwareOptions: {
    method: 'GET',
    path: 'webviewer/getCompanySoftwareOptions',
  },
  getPatientOrdersForComparison: {
    method: 'GET',
    path: 'webviewer/getPatientOrdersForComparison',
  },
  getXraiAuthToken: {
    method: 'GET',
    path: 'webviewer/getXraiAuthToken',
  },
  areScannerSoftwareOptionsAvailable: {
    method: 'GET',
    path: 'webviewer/areScannerSoftwareOptionsAvailable',
  },

  // only for online mode
  getAllFlagsState: {
    method: 'GET',
    path: 'api/v1/Settings/GetAllFlagsState',
  },
};

export const apiConfig = {
  web3DViewerBffMap,
  scannerMATApiMap,
};
