import { globalEventsKeys } from '../event-bus/supportedKeys';

export const addPlugins = (sdk, pluginsArr) => {
  sdk.raiseEvent(globalEventsKeys.CHANGE_TOOLS_MAPPER, {
    sdk,
    add: [{ pluginIds: pluginsArr }],
  });
};

export const removePlugins = (sdk, pluginsArr) => {
  sdk.raiseEvent(globalEventsKeys.CHANGE_TOOLS_MAPPER, {
    sdk,
    remove: [{ pluginIds: pluginsArr }],
  });
};
