/* eslint-disable no-unused-vars */
import createMiddleware from '../../../middlewareHelper';
import * as AT from '../../actionTypes';
import { setToolActive, setToolConfig } from './tools.actions';

//******************************
const feature = AT.TOOLS;
//******************************

/**
 * This function will be invoked for every action and not only for
 * tools actions. ({meta:{feature:AT.TOOLS}})
 * The reason we use it is because there are actions that being dispatch from
 * the plugins that should be handled here
 * @param {action,dispatch,getState} param0
 */
export const goThroughOverride = async ({ action, dispatch, getState }) => {
  const { payload, type, meta } = action;

  switch (type) {
    case AT.ACTIVATE_TOOL:
      {
        const { name, config } = payload;
        dispatch([setToolActive({ name, state: true }), setToolConfig({ name, config })]);
      }
      break;

    case AT.DEACTIVATE_TOOL:
      {
        const { name } = payload;
        dispatch(setToolActive({ name, state: false }));
      }
      break;

    case AT.CHANGE_TOOL_CONFIG:
      {
        const { name, config } = payload;
        dispatch(setToolConfig({ name, config }));
      }
      break;

    default:
    // do nothing
  }
};

export const middleware = async ({ action, dispatch, getState }) => {
  const { payload, type } = action;

  switch (type) {
    default:
    // no default
  }
};

export default createMiddleware({ feature, goThroughOverride })(middleware);
